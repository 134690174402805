import classNamees from "./AuthInput.module.css";

import {
  UseFormRegisterReturn,
  FieldError,
  Merge,
  FieldErrorsImpl,
} from "react-hook-form";

function AuthInput({
  register,
  error,
  type,
  name,
  label,
  autoFocus = false
}: {
  register: UseFormRegisterReturn<string>;
  error: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
  type: string;
  name: string;
  label: string;
  autoFocus?: boolean;
}) {
  let inputclassNames =
    "block px-4 pb-4 pt-4 w-full text-gray-900 bg-transparent rounded border-1 border-grey appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue focus:outline-none focus:ring-0 focus:border-blue focus:border-2 peer";
  if (error) {
    inputclassNames =
      "block px-4 pb-4 pt-4 w-full text-gray-900 bg-transparent rounded border-1 appearance-none dark:text-white dark:border-red border-red dark:focus:border-red focus:outline-none focus:ring-0 focus:border-red focus:border-2 peer";
  }

  let labelclassNamees =
    "absolute text-gray-500 dark:text-gray-400 duration-150 transform -translate-y-4 scale-75 top-1.5 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue peer-focus:dark:text-blue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1.5 peer-focus:scale-75 peer-focus:-translate-y-4 left-2";
  if (error) {
    labelclassNamees =
      "absolute text-red dark:text-red duration-150 transform -translate-y-4 scale-75 top-1.5 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1.5 peer-focus:scale-75 peer-focus:-translate-y-4 left-2";
  }
  return (
    <div className={classNamees["input-group"]}>
      <div>
        <div className="relative">
          <input
            type={type}
            id={name}
            className={inputclassNames}
            placeholder=" "
            autoFocus={autoFocus}
            {...register}
          />
          <label htmlFor={name} className={labelclassNamees}>
            {label}
          </label>
        </div>
        {error && error.message && (
          <p
            id="outlined_error_help"
            className="mt-2 text-xs text-red dark:text-red font-medium"
          >
            {error.message.toString()}
          </p>
        )}
      </div>
    </div>
  );
}

export default AuthInput;
