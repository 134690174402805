import {
	faClose,
	faCog,
	faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserImg from "../ui/UserImg";
import { useContext } from "react";
import { UserContext } from "../../stores/userContext";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { auth } from "../../common/firebase";
import { useNavigate } from "react-router";

function HamburgerMenu({ onClose }: { onClose: () => void }) {
	const user = useContext(UserContext);
	const { t } = useTranslation();
	const navigate = useNavigate();

	function onLogout() {
		auth.signOut().then(() => {
			navigate("/");
		});
	}

	function onSettings() {
		navigate("/settings");
		onClose();
	}

	return (
		<>
			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				layoutId="hamburger-menu-backdrop"
				className="bg-black/20 fixed top-0 left-0 w-full h-full z-10"
				onClick={onClose}
			></motion.div>
			<motion.div
				initial={{ x: -300 }}
				animate={{ x: 0 }}
				exit={{ x: -300 }}
				className="bg-blue fixed top-0 bottom-0 -left-10 z-10 py-8 pl-16 pr-6 max-w-xs flex flex-col justify-between"
				layoutId="hamburger-menu"
			>
				<FontAwesomeIcon
					icon={faClose}
					size="2xl"
					className="text-white absolute top-4 left-14 opacity-80"
					onClick={onClose}
				/>
				<div>
					<div className="w-14 h-14 mt-12">
						<UserImg />
					</div>
					<div className="mt-2 text-white text-xl">
						<h2>{user.displayName}</h2>
						<h3 className="text-lg text-white/70">{user.email}</h3>
					</div>
				</div>
				<div>
					<div
						className="flex gap-3 text-white text-lg items-center mb-5"
						onClick={onSettings}
					>
						<FontAwesomeIcon icon={faCog} size="lg" className="opacity-70" />
						<p className="text-xl font-bold">{t("home.header.settings")}</p>
					</div>
					<div
						className="flex gap-3 text-white text-lg items-center"
						onClick={onLogout}
					>
						<FontAwesomeIcon
							icon={faRightFromBracket}
							size="lg"
							className="opacity-70"
							onClick={onLogout}
						/>
						<p className="text-xl font-bold">{t("home.header.log-out")}</p>
					</div>
				</div>
			</motion.div>
		</>
	);
}

export default HamburgerMenu;
