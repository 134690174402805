import { useTranslation } from "react-i18next";
import { Order } from "../../common/types";
import { formatPrice } from "../../common/formatter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

function DetailsMobile({ order }: { order: Order }) {
	const { t, i18n } = useTranslation();
	const lang = i18n.language;

	function detailsRow(name: string, value: any) {
		return (
			<div className="mb-2">
				<p className="text-grey mr-4">{name}</p>
				<div className="">{value}</div>
			</div>
		);
	}

	function onDownload() {
		if (order.invoiceUrl) {
			window.open(order.invoiceUrl, "_blank");
		}
	}

	const additionalCosts = order.additionalCosts.filter(
		(additionalCost) => additionalCost.accepted
	);

	return (
		<div className="mt-3">
			{order.repairman &&
				detailsRow(
					t("orderdetails.repairman"),
					<p className="font-bold">{order.repairman.name}</p>
				)}
			{detailsRow(
				t("orderdetails.repair-type"),
				<p className="font-bold">{order.getName(lang)}</p>
			)}
			{detailsRow(
				t("orderdetails.address"),
				<p className="font-bold">{order.location.address}</p>
			)}
			{order.note &&
				detailsRow(
					t("orderdetails.note"),
					<p className="font-bold">{order.note}</p>
				)}
			{detailsRow(
				t("orderdetails.date"),
				<p className="font-bold">{order.getScheduledDateTime(t)}</p>
			)}
			{detailsRow(
				t("orderdetails.cost"),
				<p className="font-bold text-blue">{order.repair.priceDesc}</p>
			)}
			{additionalCosts.length > 0 &&
				detailsRow(
					t("orderdetails.additional-cost"),
					<div className="font-bold text-blue">
						{additionalCosts.map((cost) => {
							return (
								<div key={cost.id}>
									<p className="mb-2">{cost.description}</p>
									<p className="text-blue">{formatPrice(cost.amount)}</p>
								</div>
							);
						})}
					</div>
				)}
			{order.invoiceUrl &&
				detailsRow(
					t("orderdetails.invoice"),
					<p
						className="font-bold text-blue cursor-pointer"
						onClick={onDownload}
					>
						<span>
							<FontAwesomeIcon icon={faDownload} />
						</span>{" "}
						{t("orderdetails.download")}
					</p>
				)}
		</div>
	);
}

export default DetailsMobile;
