import { useContext } from "react";
import { UserContext } from "../../stores/userContext";
import Row from "../dashboard/Row";
import UserImg from "../ui/UserImg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import useBreakpoints from "../../common/useBreakpoints";

function UserBox() {
	const user = useContext(UserContext);
	const { isMobile } = useBreakpoints();

	function getCreationDate() {
		const creationTime = user.metadata.creationTime;
		if (!creationTime) {
			return null;
		}
		const creationDate = new Date(creationTime);
		const date = creationDate.getDate();
		const month = creationDate.getMonth() + 1;
		const year = creationDate.getFullYear();
		return `${date}.${month}.${year}`;
	}

	function getJoinedOn() {
		const joinedOn = getCreationDate();
		if (!joinedOn) {
			return null;
		}
		return (
			<div className="flex text-grey items-center">
				<FontAwesomeIcon icon={faCalendar} className="mr-2 mb-[2px]" />
				<p>Joined on {getCreationDate()}</p>
			</div>
		);
	}

	return (
		<Row className="flex justify-between items-center">
			<div className="flex items-center">
				<div className="h-20 w-20 mr-6">
					<UserImg />
				</div>
				<div>
					<h1 className="text-black text-lg font-bold">{user.displayName}</h1>
					<p>{user.email}</p>
					<div className="mt-3">{isMobile && getJoinedOn()}</div>
				</div>
			</div>
			{!isMobile && getJoinedOn()}
		</Row>
	);
}

export default UserBox;
