import { useTranslation } from "react-i18next";
import { Image } from "../../common/types";

function Images({ images }: { images: Image[] }) {
	const { t } = useTranslation();

	function openImage(image: Image) {
		window.open(image.url, "_blank");
	}

	if (images.length === 0) {
		return <></>;
	}

	return (
		<div>
			<h2 className="mb-2 text-start">
				{t("orderdetails.pictures")} ({images.length})
			</h2>
			<div className="flex flex-wrap mb-5 gap-3 items-center w-full overflow-auto">
				{images.map((image, index) => (
					<div key={image.id}>
						<img
							src={image.url}
							alt="repair"
							className="w-20 h-20 rounded-lg object-cover cursor-pointer bg-grey"
							onClick={() => openImage(image)}
						/>
					</div>
				))}
			</div>
		</div>
	);
}

export default Images;
