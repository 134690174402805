import { useTranslation } from "react-i18next";
import useTimeSlots from "../../common/datahooks/useTimeSlots";
import { useState } from "react";
import Dropdown, { DropdownOption } from "../ui/input/Dropdown";
import {
	AdditionalCostSchedule,
	DaySchedule,
	TimeSlot,
} from "../../common/types";
import Row from "../dashboard/Row";
import useAdditionalCostSchedules from "../../common/datahooks/useAdditionalCostSchedules";

function ChooseTimeSlot({
	setTimeSlot,
	urgent,
	setUrgent,
}: {
	setTimeSlot: (timeSlot: string) => void;
	urgent: boolean;
	setUrgent: (urgent: boolean) => void;
}) {
	const { t } = useTranslation();
	const { data: timeSlots } = useTimeSlots();
	const { data: additionalCostSchedules } = useAdditionalCostSchedules();

	const defaultDate = new Date();
	let [selectedDate, setSelectedDate] = useState<Date>(defaultDate);
	let [selectedTimeSlot, setSelectedTimeSlot] = useState<TimeSlot | null>(null);

	let from = new Date();
	let to = new Date();
	to.setMonth(to.getMonth() + 1);

	if (timeSlots && timeSlots.length) {
		from = new Date(timeSlots[0].from);
		to = new Date(timeSlots[timeSlots.length - 1].to);
	}

	const availableTimeSlots =
		timeSlots?.filter((timeSlot) => {
			return dateToInputDate(timeSlot.from) === dateToInputDate(selectedDate);
		}) || [];

	if (availableTimeSlots.length > 0 && !selectedTimeSlot) {
		selectedTimeSlot = availableTimeSlots[0];
	}

	function onSelectDate(e: React.ChangeEvent<HTMLInputElement>) {
		const date = new Date(e.target.value);
		if (date > new Date()) {
			setSelectedDate(date);
		} else {
			setSelectedDate(new Date());
		}
		setSelectedTimeSlot(null);
	}

	function onSelectTimeSlot(selected: DropdownOption) {
		const timeSlot = selected as TimeSlot;
		setSelectedTimeSlot(timeSlot);

		setTimeSlot(timeSlot.from.toISOString());
	}

	let additionalCost: AdditionalCostSchedule | undefined;
	if (additionalCostSchedules && selectedTimeSlot) {
		additionalCostSchedules.forEach((schedule) => {
			if (isOverlap(selectedTimeSlot as TimeSlot, schedule)) {
				if (!additionalCost) {
					additionalCost = schedule;
				} else if (additionalCost && additionalCost.amount < schedule.amount) {
					additionalCost = schedule;
				}
			}
		});
	}

	return (
		<Row>
			<div className="sm:flex justify-between">
				<h2 className="text-black font-bold text-lg mb-2 sm:mb-6">
					{t("new-repair.date-and-time")}
				</h2>
				<input
					disabled={urgent}
					min={dateToInputDate(from)}
					max={dateToInputDate(to)}
					value={dateToInputDate(selectedDate)}
					onChange={onSelectDate}
					type="date"
					className={`rounded-lg p-4 border-grey focus:border-blue w-full sm:w-[311px] hover:border-blue transition-[border-color] shadow-none ${
						urgent ? "opacity-50 pointer-events-none" : ""
					}`}
				/>
			</div>
			<div className="flex justify-end mt-6">
				<Dropdown
					disabled={urgent}
					selected={selectedTimeSlot}
					options={availableTimeSlots}
					onSelectedChange={onSelectTimeSlot}
					placeholder={t("new-repair.no-time-available")}
				/>
			</div>
			{additionalCost && (
				<div className="text-right mt-2 text-red">
					{t("home.additional-cost.additional-cost")}:
					<span className="text-black ml-2">
						{additionalCost.nameSR + "(" + additionalCost.amount + "rsd)"}
					</span>
				</div>
			)}
		</Row>
	);
}

function dateToInputDate(date: Date) {
	const year = date.getFullYear();
	const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 to month because it's zero-based
	const day = date.getDate().toString().padStart(2, "0");

	return `${year}-${month}-${day}`;
}

//Calculate if time slot overlaps addtional cost schedule
function isOverlap(
	timeSlot: TimeSlot,
	workSchedule: AdditionalCostSchedule
): boolean {
	const daysOfWeek = [
		"sunday",
		"monday",
		"tuesday",
		"wednesday",
		"thursday",
		"friday",
		"saturday",
	];

	const taskStart = new Date(timeSlot.from);
	const taskEnd = new Date(timeSlot.to);

	const taskDay = daysOfWeek[taskStart.getDay()]; // Get the day of the week for the task

	// If the task spans across multiple days, we need to check each day
	let currentDay = taskDay;
	while (taskStart <= taskEnd) {
		const daySchedule = workSchedule[
			currentDay as keyof AdditionalCostSchedule
		] as DaySchedule;
		if (daySchedule.enabled) {
			const scheduleStart = parseTime(daySchedule.startTime);
			let scheduleEnd = parseTime(daySchedule.endTime);

			// Adjust schedule end time if it's on the next day
			if (scheduleEnd < scheduleStart) {
				console.log("scheduleEnd < scheduleStart " + taskDay);
				scheduleEnd += 24 * 60 * 60 * 1000; // Add 24 hours
			}

			// Convert task start and end times to milliseconds since midnight
			const taskStartTime =
				taskStart.getHours() * 60 * 60 * 1000 +
				taskStart.getMinutes() * 60 * 1000;
			const taskEndTime =
				taskEnd.getHours() * 60 * 60 * 1000 + taskEnd.getMinutes() * 60 * 1000;

			// Check for overlap
			if (
				(taskStartTime > scheduleStart && taskStartTime < scheduleEnd) ||
				(taskEndTime > scheduleStart && taskEndTime < scheduleEnd)
			) {
				return true; // Overlap found
			}
		}

		// Move to the next day
		taskStart.setDate(taskStart.getDate() + 1);
		currentDay = daysOfWeek[taskStart.getDay()];
	}

	// Check for overflow from one day to the next
	const overflowSchedule = { ...workSchedule } as any;
	let found = false;
	for (let i = 0; i < 7; i++) {
		const daySchedule = workSchedule[
			daysOfWeek[i] as keyof AdditionalCostSchedule
		] as DaySchedule;
		const startTime = parseTime(daySchedule.startTime);
		const endTime = parseTime(daySchedule.endTime);
		const nextDayIndex = (i + 1) % 7;
		if (daySchedule.enabled && startTime > endTime) {
			overflowSchedule[daysOfWeek[nextDayIndex]] = {
				enabled: true,
				startTime: "00:00",
				endTime: daySchedule.endTime,
			};
			found = true;
		} else {
			overflowSchedule[daysOfWeek[nextDayIndex]] = {
				enabled: false,
			};
		}
	}
	if (found) {
		console.log("overflowSchedule", overflowSchedule);
		return isOverlap(timeSlot, overflowSchedule as AdditionalCostSchedule);
	}

	return false; // No overlap found
}

// Helper function to parse time in HH:MM format to milliseconds since midnight
function parseTime(timeStr: string) {
	if(!timeStr) return 0;
	const [hours, minutes] = timeStr.split(":").map(Number);
	return hours * 60 * 60 * 1000 + minutes * 60 * 1000;
}

export default ChooseTimeSlot;
