import Popup from "../ui/popup/Popup";
import { useTranslation } from "react-i18next";
import { email, phoneNumber } from "../../common/contactInfo";
import Button from "../ui/button/Button";
import { Order } from "../../common/types";
import { post } from "../../common/http";
import toast from "react-hot-toast";

function CancelPopup({
	onClose,
	order,
	onCanceled,
}: {
	onClose: () => void;
	order: Order;
	onCanceled: () => void;
}) {
	const { t } = useTranslation();
	const emailLink = `<a class='text-blue' href="mailto:${email}">${email}</a>`;
	const phoneLink = `<a class='text-blue' href="tel:${phoneNumber}">${phoneNumber}</a>`;

	function onCancel() {
		post(`/orders/${order.id}/cancel`, {})
			.then(() => {
				onClose();
			})
			.then(() => {
				onCanceled();
			})
			.catch(() => {
				toast.error(t("home.cancel-popup.failed-to-cancel"));
			});
	}

	if (allowCancel(order)) {
		return (
			<Popup onClose={onClose}>
				<h1 className="text-center mb-2 font-medium text-lg">
					{t("home.cancel-popup.title")}
				</h1>
				<p className="text-center mb-4 sm:px-3">
					{t("home.cancel-popup.body-are-you-sure")}
				</p>
				<div className="flex gap-4 flex-col sm:flex-row">
					<Button
						name={t("home.cancel-popup.cancel")}
						onClick={onCancel}
						background="red"
					/>
					<Button name={t("home.cancel-popup.close")} onClick={onClose} />
				</div>
			</Popup>
		);
	}

	return (
		<Popup onClose={onClose}>
			<h1 className="text-center mb-2 font-medium text-lg">
				{t("home.cancel-popup.title")}
			</h1>
			<p
				className="text-center mb-4 sm:px-3"
				dangerouslySetInnerHTML={{
					__html: t("home.cancel-popup.body", {
						email: emailLink,
						phone: phoneLink,
						interpolation: { escapeValue: false },
					}),
				}}
			></p>
			<Button name={t("home.cancel-popup.close")} onClick={onClose} />
		</Popup>
	);
}

function allowCancel(order: Order) {
	// Allow canceling if the order is created in less than 1 hour
	return order.createdAt.getTime() > Date.now() - 60 * 60 * 1000;
}

export default CancelPopup;
