import { useTranslation } from "react-i18next";
import TiledContainer from "../../common/tiledContainer/TiledContainer";
import TiledContainerSubtitle from "../../common/tiledContainer/TiledContainerSubtitle";
import TiledContainerTitle from "../../common/tiledContainer/TiledContainerTitle";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

function TitleBox() {
	const { t } = useTranslation();

	return (
		<TiledContainer>
			<div className="flex justify-between mt-14 mb-8 max-w-4xl mx-auto px-4">
				<div>
					<TiledContainerTitle text={t("new-repair.title")} className="mb-2" />
					<TiledContainerSubtitle text={t("new-repair.subtitle")} />
				</div>
				<NavLink
					className="flex gap-3 items-center absolute top-6 right-6 sm:relative sm:top-0 sm:right-0"
					to={"/home"}
				>
					<span className="text-grey">{t("new-repair.close")}</span>
					<div className="rounded-full bg-blue w-8 h-8 flex items-center justify-center">
						<FontAwesomeIcon icon={faClose} className="text-white" />
					</div>
				</NavLink>
			</div>
		</TiledContainer>
	);
}

export default TitleBox;
