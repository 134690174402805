import { faHistory } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Order } from "../../common/types";
import { formatPrice } from "../../common/formatter";
import Button from "../ui/button/Button";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

function HistoryListMobile({
	orders,
	isLoading,
}: {
	orders: Order[] | undefined;
	isLoading: boolean;
}) {
	const { t, i18n } = useTranslation();

	const lang = i18n.language;

	const navigate = useNavigate();

	return (
		<div className="px-4 py-8">
			<div className="flex text-grey text-2xl mb-8 pb-4 items-center font-bold border-b botder-grey">
				<FontAwesomeIcon icon={faHistory} className="mr-2" />
				<div>{t("home.history.title")}</div>
			</div>
			{isLoading && <p className="text-center">Loading...</p>}
			{!isLoading && orders && orders.length === 0 && (
				<p className="text-center text-grey">{t("home.history.empty")}</p>
			)}
			{orders &&
				orders.length > 0 &&
				orders.map((order) => {
					return (
						<div className="mb-6" key={order.id}>
							<div className="flex justify-between items-center">
								<div>
									<div className="font-bold mb-1">{order.getName(lang)}</div>
									{order.location && (
										<div className="font-semibold text-grey">
											{order.location.address}
										</div>
									)}
									<div className="text-grey font-semibold text-sm mb-1">
										{order.scheduledTime.toLocaleDateString()}
									</div>
								</div>
								<div>
									<div className="font-bold text-green text-lg whitespace-nowrap">
										{formatPrice(order.paidAmount)}
									</div>
								</div>
							</div>
							<Button
								onClick={() => navigate(`/order/${order.id}`)}
								className="mt-4"
								background="blue-light"
								color="blue"
								name={t("home.history.details")}
							/>
						</div>
					);
				})}
		</div>
	);
}

export default HistoryListMobile;
