import { useState } from "react";
import Input from "../../ui/input/Input";
import Button from "../../ui/button/Button";
import Popup from "../../ui/popup/Popup";

function ChangePopup({
	inputs,
	title,
	onSave,
	onClose,
	loading,
}: {
	inputs: ChangeInputProps[];
	title: string;
	onSave: (values: string[]) => void;
	onClose: () => void;
	loading: boolean;
}) {
	const [values, setValues] = useState(
		inputs.map((input) => input.defaultValue || "")
	);

	function onChange(value: string, index: number) {
		setValues((prev) => {
			const newValues = [...prev];
			newValues[index] = value;
			return newValues;
		});
	}

	function onSubmit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();
		onSave(values.map((value) => value || ""));
	}

	const disabled = loading || values.some((value) => value === "");

	return (
		<Popup onClose={onClose}>
			<form onSubmit={onSubmit}>
				<h1 className="text-xl font-bold mb-4 text-black">{title}</h1>
				<div className="inputs flex flex-col gap-3">
					{inputs.map((input, index) => {
						return (
							<Input
								key={index}
								type={input.type || "text"}
								placeholder={input.placeholder}
								value={values[index]}
								onChange={(value) => onChange(value, index)}
							/>
						);
					})}
				</div>
				<Button
					name="Save"
					width="full"
					className="mx-auto mt-6"
					loading={loading}
					disabled={disabled}
					type="submit"
				/>
			</form>
		</Popup>
	);
}

export type ChangeInputProps = {
	defaultValue?: string;
	placeholder?: string;
	type?: string;
};

export default ChangePopup;
