import { useTranslation } from "react-i18next";
import google from "../../../assets/images/google.svg";

function GoogleButton({ onClick }: { onClick?: () => void }) {
	const { t } = useTranslation();
	return (
		<button
			className="border boder-grey rounded w-full h-[52px] flex justify-center items-center"
			onClick={onClick}
			type="button"
		>
			<img src={google} alt="Google Logo" className="mr-5" />
			<span>{t("auth.login.continue-with-google")}</span>
		</button>
	);
}

export default GoogleButton;
