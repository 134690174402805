import logo from "../../assets/images/logo.svg";
function Header() {

	return (
		<div className="bg-blue flex h-16 justify-center items-center sm:justify-center">
			<img src={logo} alt="logo" className="h-8" />
		</div>
	);
}

export default Header;
