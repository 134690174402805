import { ReactNode } from "react";
import { motion } from "framer-motion";
import useBreakpoints from "../../../common/useBreakpoints";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

function Popup({
	onClose,
	children,
}: {
	onClose: () => void;
	children: ReactNode;
}) {
	const { isMobile } = useBreakpoints();

	function getCloseIcon() {
		return (
			<FontAwesomeIcon
				icon={faClose}
				className="absolute top-[14px] right-4 text-grey cursor-pointer text-2xl"
				onClick={onClose}
			/>
		);
	}

	function getMobileView() {
		return (
			<motion.div
				layoutId="change-popup"
				animate={{ opacity: 1, y: "-50%" }}
				initial={{ opacity: 0, y: "-80%" }}
				exit={{ opacity: 0, y: "-80%" }}
				className="left-5 right-5 fixed top-1/2 bg-white p-8 rounded-xl z-30"
			>
				{getCloseIcon()}
				{children}
			</motion.div>
		);
	}

	function getDesktopView() {
		return (
			<motion.div
				layoutId="change-popup"
				animate={{ opacity: 1, y: "-50%", x: "-50%" }}
				initial={{ opacity: 0, y: "-80%", x: "-50%" }}
				exit={{ opacity: 0, y: "-80%", x: "-50%" }}
				className="center-absolute bg-white p-8 rounded-xl m-6 z-30"
			>
				{getCloseIcon()}
				{children}
			</motion.div>
		);
	}

	return (
		<>
			<motion.div
				layoutId="change-popup-backdrop"
				initial={{ opacity: 0 }}
				animate={{ opacity: 0.2 }}
				exit={{ opacity: 0 }}
				className="fixed w-full h-full bg-black left-0 top-0 z-30"
				onClick={onClose}
			></motion.div>

			{isMobile && getMobileView()}
			{!isMobile && getDesktopView()}
		</>
	);
}

export default Popup;
