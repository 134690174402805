import { useTranslation } from "react-i18next";
import TitleBox from "./TitleBox";
import RepairType from "./RepairType";
import { useState } from "react";
import { Repair, RepairType as RepairTypeType } from "../../common/types";
import Images from "./Images";
import ChooseLocation from "./ChooseLocation";
import RepairDescription from "./RepairDescription";
import ChooseTimeSlot from "./ChooseTimeSlot";
import AddPhone from "./AddPhone";
import Button from "../ui/button/Button";
import { NavLink, useNavigate } from "react-router-dom";
import { LocationType } from "./ChooseLocation";
import toast from "react-hot-toast";
import { post } from "../../common/http";

const defaultNewRepair = {
	repairType: null,
	repair: null,
	images: [],
	location: null,
	repairDesc: "",
	phone: "",
	urgent: false,
	scheduledTime: "",
};

function NewRepair() {
	const { t } = useTranslation();
	const [newRepair, setNewRepair] = useState<NewRepairType>(defaultNewRepair);
	const [loading, setLoading] = useState<boolean>(false);

	const navigate = useNavigate();

	function onScheduleRepair() {
		if (!validateInput()) {
			return;
		}
		setLoading(true);
		post("/orders", newRepair)
			.then(() => {
				navigate("/home");
			})
			.catch((error) => {
				console.log(error.response);
				const message = error.response?.data?.message;
				if (message === "Already scheduled date.") {
					toast.error(t("new-repair.errors.already-scheduled"));
				} else {
					toast.error(t("new-repair.errors.failed-to-schedule"));
				}
			})
			.finally(() => {
				setLoading(false);
			});
	}

	function validateInput() {
		if (!newRepair.repairType) {
			toast.error(t("new-repair.errors.choose-repair"));
			return false;
		}
		if (!newRepair.location) {
			toast.error(t("new-repair.errors.choose-location"));
			return false;
		}
		if (!newRepair.scheduledTime && !newRepair.urgent) {
			toast.error(t("new-repair.errors.choose-time"));
			return false;
		}
		if (!newRepair.phone) {
			toast.error(t("new-repair.errors.add-phone"));
			return false;
		}
		return true;
	}

	function addImage(image: string) {
		const exists = newRepair.images.find((img) => img.url === image);
		if (exists) {
			return;
		}
		setNewRepair((prev) => ({
			...prev,
			images: [
				...prev.images,
				{
					url: image,
				},
			],
		}));
	}

	function removeImage(image: string) {
		setNewRepair((prev) => ({
			...prev,
			images: prev.images.filter((img) => img.url !== image),
		}));
	}

	function setLocation(location: LocationType) {
		setNewRepair((prev) => ({
			...prev,
			location: location,
		}));
	}

	function setDescription(description: string) {
		setNewRepair((prev) => ({
			...prev,
			repairDesc: description,
		}));
	}

	function setPhone(phone: string) {
		setNewRepair((prev) => ({
			...prev,
			phone: phone,
		}));
	}

	function setTimeSlot(timeSlot: string) {
		setNewRepair((prev) => ({
			...prev,
			scheduledTime: timeSlot,
		}));
	}

	function setUrgent(urgent: boolean) {
		setNewRepair((prev) => ({
			...prev,
			urgent: urgent,
		}));
	}

	return (
		<>
			<TitleBox />
			<div className="max-w-4xl mx-auto">
				<RepairType newRepair={newRepair} setNewRepair={setNewRepair} />
				<Images addImage={addImage} removeImage={removeImage} />
				<ChooseLocation setLocation={setLocation} />
				<RepairDescription setDescription={setDescription} />
				<ChooseTimeSlot
					setTimeSlot={setTimeSlot}
					urgent={newRepair.urgent}
					setUrgent={setUrgent}
				/>
				<AddPhone setPhoneNumber={setPhone} />
				<div className="flex items-center flex-col">
					<Button
						width="big"
						className="mt-6 w-[311px]"
						name={t("new-repair.schedule-repair")}
						onClick={onScheduleRepair}
						loading={loading}
					/>
					<NavLink
						className="text-center w-[311px] text-red p-4 mt-2 mb-8 cursor-pointer"
						to={"/home"}
					>
						{" "}
						{t("new-repair.cancel-repair")}
					</NavLink>
				</div>
			</div>
		</>
	);
}

export default NewRepair;

export type NewRepairType = {
	repairType: RepairTypeType | null;
	repair: Repair | null;
	images: { url: string }[];
	location: LocationType | null;
	repairDesc: string;
	phone: string;
	urgent: boolean;
	scheduledTime: string;
};
