import { Outlet } from "react-router";
import Header from "../header/Header";
import Footer from "../footer/Footer";

function Dashboard() {
	return (
		<div className="dashboard-container min-h-screen flex flex-col justify-between">
			<div>
				<Header />
				<Outlet />
			</div>
			<Footer />
		</div>
	);
}

export default Dashboard;
