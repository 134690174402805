import { XCircleIcon } from "@heroicons/react/20/solid";

export type AuthErrorType = {
	title: string;
	bodyLines?: string[];
};

function AuthError({ title, bodyLines }: AuthErrorType) {
	return (
		<div className="rounded-md bg-red/5 p-4">
			<div className="flex">
				<div className="flex-shrink-0">
					<XCircleIcon className="h-5 w-5 text-red" aria-hidden="true" />
				</div>
				<div className="ml-3">
					<h3 className="text-sm font-medium text-red">{title}</h3>
					{bodyLines && bodyLines.length > 0 && (
						<div className="mt-2 text-sm text-red">
							<ul className="list-disc space-y-1">
								{bodyLines.map((bodyLine, index) => (
									<li key={index} className="list-none">{bodyLine}</li>
								))}
							</ul>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default AuthError;
