import { SubmitHandler, useForm } from "react-hook-form";
import AuthInput from "../components/ui/input/AuthInput";
import AuthLayout from "../components/auth/AuthLayout";
import { Link, useNavigate } from "react-router-dom";
import Button from "../components/ui/button/Button";
import { useTranslation } from "react-i18next";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { auth } from "../common/firebase";
import { useState } from "react";
import AuthError, { AuthErrorType } from "../components/auth/AuthError";

export type Inputs = {
	name: string;
	email: string;
	password: string;
	confirmPassword: string;
};

function Register() {
	const {
		register,
		handleSubmit,
		trigger,
		watch,
		formState: { errors, isSubmitted },
	} = useForm<Inputs>();
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [error, setError] = useState<AuthErrorType | null>(null);
	const [termsAccepted, setTermsAccepted] = useState(false);
	const onSubmit: SubmitHandler<Inputs> = (data) => {
		setLoading(true);
		if (!termsAccepted) {
			setError({
				title: t("auth.errors.terms.title"),
				bodyLines: [t("auth.errors.terms.body")],
			});
			setLoading(false);
			return;
		}
		createUserWithEmailAndPassword(auth, data.email, data.password)
			.then((userCredential) => {
				const user = userCredential.user;
				updateProfile(user, {
					displayName: data.name,
				}).finally(() => {
					navigate("/home");
					setLoading(false);
				});
			})
			.catch((error) => {
				setLoading(false);
				switch (error.code) {
					case "auth/email-already-in-use":
						setError({
							title: t("auth.errors.email-exists.title"),
							bodyLines: [
								t("auth.errors.email-exists.body", { email: data.email }),
							],
						});
						break;
					default:
						setError({
							title: error.name,
							bodyLines: [error.message],
						});
				}
			});
	};

	return (
		<AuthLayout onSubmit={handleSubmit(onSubmit)}>
			<h1 className="text-center text-3xl mb-1">{t("auth.register.title")}</h1>
			<p className="text-center mb-6 text-sm">{t("auth.register.subtitle")}</p>
			<AuthInput
				name="name"
				label={t("auth.register.name")}
				type="text"
				error={errors.name}
				autoFocus={true}
				register={register("name", {
					required: t("auth.errors.name-empty"),
				})}
			/>
			<div className="h-1"></div>
			<AuthInput
				name="email"
				label={t("auth.register.email")}
				type="email"
				error={errors.email}
				register={register("email", {
					required: t("auth.errors.email-empty"),
				})}
			/>
			<div className="h-1"></div>
			<AuthInput
				name="password"
				label={t("auth.register.password")}
				type="password"
				error={errors.password}
				register={register("password", {
					required: t("auth.errors.password-empty"),
					minLength: {
						value: 6,
						message: t("auth.errors.minumun-passsword-length"),
					},
					onChange() {
						if (isSubmitted) {
							trigger("confirmPassword");
						}
					},
				})}
			/>
			<div className="h-1"></div>
			<AuthInput
				name="confirmPassword"
				label={t("auth.register.confirm-password")}
				type="password"
				error={errors.confirmPassword}
				register={register("confirmPassword", {
					required: t("auth.errors.confirm-password-empty"),
					validate: (value) =>
						value === watch("password") ||
						t("auth.errors.passwords-dont-match"),
				})}
			/>
			<div className="flex items-center mb-2">
				<input
					type="checkbox"
					className="mr-2 rounded cursor-pointer hover:border-blue transition-[border-color]"
					onInput={($event) => {
						setTermsAccepted(($event.target as HTMLInputElement).checked);
					}}
				/>
				<p>
					{t("auth.register.agree-terms")}
					<Link to={"/terms"} className="underline ml-1" target="_blank">
						{t("auth.register.terms")}
					</Link>
				</p>
			</div>
			{error && <AuthError title={error.title} bodyLines={error.bodyLines} />}
			<div className="h-4"></div>
			<Button
				name={t("auth.register.create-account")}
				type="submit"
				loading={loading}
			/>
			<p className="mt-3">
				{t("auth.register.already-have-an-account")}{" "}
				<Link to="/" className="text-blue">
					{t("auth.register.login")}
				</Link>
			</p>
		</AuthLayout>
	);
}

export default Register;
