import { useEffect, useState } from "react";

const widthTablet = 640;
//const widthLaptop = 1024;
const widthDesktop = 1280;

function useViewportWidth() {
	const [width, setWidth] = useState(
		typeof window === "undefined" ? 0 : window.innerWidth
	);
	useEffect(() => {
		function handleResize() {
			setWidth(window.innerWidth);
		}
		window.addEventListener("resize", handleResize);
		setWidth(window.innerWidth);
		return () => window.removeEventListener("resize", handleResize);
	}, []);
	return width;
}

function useBreakpoints() {
	const width = useViewportWidth();
	const isMobile = width < widthTablet;
	const isDesktop = width >= widthDesktop;

	return {
		isMobile,
		isDesktop,
	};
}

export default useBreakpoints;
