import { useTranslation } from "react-i18next";
import { Order } from "../../common/types";
import { formatPrice } from "../../common/formatter";

function Paid({ order }: { order: Order }) {
	const { t } = useTranslation();
	return (
		<div className="bg-green/10 p-4 flex justify-between items-center rounded text-lg mt-6 sm:mt-8">
			<span>{t("orderdetails.paid")}</span>
			<span className="text-green font-bold">
				{formatPrice(order.paidAmount)}
			</span>
		</div>
	);
}

export default Paid;
