import { useNavigate, useParams } from "react-router-dom";
import useHistory from "../common/datahooks/useHistory";
import PageLoader from "../common/loaders/PageLoader";
import OrderDetails from "../components/orderdetails/OrderDetails";
import useActiveRepairOrder from "../common/datahooks/useActiveRepairs";

function OrderDetailsPage() {
	const { data: history, isLoading: loadingHistory } = useHistory();

	const { data: active, isLoading: loadingActive } = useActiveRepairOrder();

	const { orderId } = useParams();

	const navigate = useNavigate();

	if (loadingActive || loadingHistory) return <PageLoader />;

	if (active && active.id + "" === orderId) {
		return <OrderDetails order={active} />;
	}

	if (!history) {
		navigate("/home");
		return <></>;
	}

	const order = history.find((order) => order.id + "" === orderId);

	if (!order) {
		navigate("/home");
		return <></>;
	}

	return <OrderDetails order={order} />;
}

export default OrderDetailsPage;
