import { SubmitHandler, useForm } from "react-hook-form";
import AuthInput from "../components/ui/input/AuthInput";
import AuthLayout from "../components/auth/AuthLayout";
import { Link } from "react-router-dom";
import { auth } from "../common/firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import { FirebaseError } from "firebase/app";
import { useState } from "react";
import AuthError, { AuthErrorType } from "../components/auth/AuthError";
import { useTranslation } from "react-i18next";
import Button from "../components/ui/button/Button";

export type Inputs = {
	email: string;
};

function ForgotPassword() {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<Inputs>();

	const [error, setError] = useState<AuthErrorType | null>(null);
	const [sentEmail, setSentEmail] = useState<string | null>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const { t } = useTranslation();

	const onSubmit: SubmitHandler<Inputs> = (data) => {
		setLoading(true);
		sendPasswordResetEmail(auth, data.email)
			.then(() => {
				setSentEmail(data.email);
			})
			.catch((error: FirebaseError) => {
				switch (error.code) {
					case "auth/user-not-found":
						setError({
							title: t("auth.errors.email-not-found.title"),
							bodyLines: [t("auth.errors.email-not-found.body")],
						});
						break;
					default:
						setError({
							title: error.name,
							bodyLines: [error.message],
						});
				}
			}).finally(() => {
				setLoading(false);
			});
	};

	if (sentEmail) {
		return (
			<AuthLayout onSubmit={() => {}}>
				<h1 className="text-center text-3xl mb-6">
					{t("auth.forgot-password.title")}
				</h1>
				<p className="text-center mb-6 text-sm">
					{t("auth.forgot-password.email-sent", { email: sentEmail })}
				</p>
				<div className="text-center">
					<p className="mt-3 flex justify-center">
						<Link to="/" className="text-blue">
							{t("auth.forgot-password.back-to-login")}
						</Link>
					</p>
				</div>
			</AuthLayout>
		);
	}

	return (
		<AuthLayout onSubmit={handleSubmit(onSubmit)}>
			<h1 className="text-center text-3xl mb-1">
				{t("auth.forgot-password.title")}
			</h1>
			<p className="text-center mb-6 text-sm">
				{t("auth.forgot-password.subtitle")}
			</p>
			<AuthInput
				name="email"
				label={t("auth.login.email")}
				type="email"
				error={errors.email}
				autoFocus={true}
				register={register("email", {
					required: t("auth.errors.email-empty"),
				})}
			/>
			<div className="h-1"></div>
			{error && <AuthError title={error.title} bodyLines={error.bodyLines} />}
			<div className="h-4"></div>
			<Button
				name={t("auth.forgot-password.request-reset-link")}
				type="submit"
				loading={loading}
			/>
			<p className="mt-3 flex justify-center">
				<Link to="/" className="text-blue">
					{t("auth.forgot-password.back-to-login")}
				</Link>
			</p>
		</AuthLayout>
	);
}

export default ForgotPassword;
