function TiledContainerSubtitle({
	text,
	className = "",
}: {
	text: string;
	className?: string;
}) {
	return <p className={"text-lg " + className}>{text}</p>;
}

export default TiledContainerSubtitle;
