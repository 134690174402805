function Row({
	children,
	className,
}: {
	children: React.ReactNode;
	className?: string;
}) {
	return (
		<div className={`mx-4 sm:px-4 sm:mx-0 py-6 border-b border-grey ${className}`}>
			{children}
		</div>
	);
}

export default Row;