import { useTranslation } from "react-i18next";
import Dropdown from "../ui/input/Dropdown";
import useRepairs from "../../common/datahooks/useRepairs";
import { NewRepairType } from "./NewRepair";
import { Repair, RepairType as RepairTypeType } from "../../common/types";
import Row from "../dashboard/Row";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins } from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";

function RepairType({
	newRepair,
	setNewRepair,
}: {
	newRepair: NewRepairType;
	setNewRepair: any;
}) {
	const { t, i18n } = useTranslation();
	const lang = i18n.language;

	const { data: repairTypes } = useRepairs();
	let selectedRepairType = newRepair.repairType;

	let repairCategoryOptions: RepairTypeType[] = [];
	if (repairTypes) {
		repairCategoryOptions = repairTypes.map((repairType) => {
			return {
				...repairType,
				text: repairType.getName(lang),
			};
		});
	}

	let slectedRepair = newRepair.repair;
	let repairTypeOptions: Repair[] = [];
	if (selectedRepairType) {
		repairTypeOptions = selectedRepairType.repairs.map((repairType) => {
			return {
				...repairType,
				text: repairType.getName(lang),
			};
		});
	}

	function onSetNewRepairCategory(selected: RepairTypeType) {
		setNewRepair({
			...newRepair,
			repairType: selected,
		});
	}

	function onSetNewRepairType(selected: Repair) {
		setNewRepair({
			...newRepair,
			repair: selected,
		});
	}

	function onRepairTypeClick() {
		if (!selectedRepairType) {
			toast(t("new-repair.category-not-choosen"), {
				icon: "⚠️",
			});
		}
	}

	return (
		<>
			<Row>
				<div className="sm:flex justify-between items-center">
					<h2 className="text-black font-bold text-lg mb-2 sm:mb-0">
						{t("new-repair.repair-category")}
					</h2>
					<Dropdown
						options={repairCategoryOptions}
						selected={selectedRepairType}
						placeholder={t("new-repair.choose-category")}
						onSelectedChange={(selected) =>
							onSetNewRepairCategory(selected as RepairTypeType)
						}
					/>
				</div>
			</Row>
			<Row>
				<div className="sm:flex justify-between items-center">
					<div className="mb-2 sm:mb-0">
						<h2 className="text-black font-bold text-lg">
							{t("new-repair.repair-type")}
						</h2>
						{slectedRepair && (
							<p className="mt-1">
								<FontAwesomeIcon
									icon={faCoins}
									className="mr-2 text-yellow"
								/>
								<span className="font-bold text-blue">
									{slectedRepair.priceDesc}
								</span>
							</p>
						)}
					</div>
					<Dropdown
						options={repairTypeOptions}
						selected={slectedRepair}
						placeholder={t("new-repair.choose-type")}
						onClick={onRepairTypeClick}
						onSelectedChange={(selected) =>
							onSetNewRepairType(selected as Repair)
						}
					/>
				</div>
			</Row>
		</>
	);
}

export default RepairType;
