import Button from "../ui/button/Button";
import Row from "../dashboard/Row";
import { UserContext } from "../../stores/userContext";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import ChangePopup from "./changepopup/ChangePopup";
import { updateProfile } from "firebase/auth";
import useBreakpoints from "../../common/useBreakpoints";
import { AnimatePresence } from "framer-motion";
import toast from "react-hot-toast";
import { get } from "../../common/http";

function ChangeName() {
	const user = useContext(UserContext);
	const [showChangeName, setShowChangeName] = useState(false);
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();

	const { isMobile } = useBreakpoints();

	function onSave(values: string[]) {
		setLoading(true);
		updateProfile(user, {
			displayName: values[0],
		})
			.then(() => {
				setShowChangeName(false);
				toast.success(t("settings.success-change-name"), {
					position: "top-right",
				});
				get("users/update").then(() => {});
			})
			.catch((error) => {
				toast.error(error.message, {
					position: "top-right",
				});
			})
			.finally(() => {
				setLoading(false);
			});
	}

	return (
		<>
			<Row className="sm:flex justify-between items-center">
				<div>
					<h1 className="text-black text-lg font-bold">
						{t("settings.full-name")}
					</h1>
					<p>{user.displayName}</p>
				</div>
				<div className="mt-3 sm:mt-0">
					<Button
						name={t("settings.change")}
						width={isMobile ? "full" : "big"}
						onClick={setShowChangeName.bind(null, true)}
					/>
				</div>
			</Row>
			<AnimatePresence>
				{showChangeName && (
					<ChangePopup
						inputs={[
							{
								placeholder: t("settings.full-name"),
								defaultValue: user.displayName || "",
							},
						]}
						title={t("settings.update-name")}
						onSave={onSave}
						onClose={setShowChangeName.bind(null, false)}
						loading={loading}
					/>
				)}
			</AnimatePresence>
		</>
	);
}

export default ChangeName;
