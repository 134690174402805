function TiledContainerTitle({
	text,
	className = "",
}: {
	text: string;
	className?: string;
}) {
	return <h1 className={"text-blue font-bold text-2xl " + className}>{text}</h1>;
}

export default TiledContainerTitle;
