import { useContext } from "react";
import { UserContext } from "../../stores/userContext";
import { Outlet, Navigate } from "react-router-dom";
import { User } from "firebase/auth";

function AuthGuard({ loggedIn }: { loggedIn: boolean }) {
	let user: User | null = useContext(UserContext);
	if (user.uid.length === 0) {
		user = null;
	}

	//Redirect to home if user is logged in and trying to access login page
	if (user && !loggedIn) {
		return <Navigate to="/home" replace={true} />;
	}

	//Redirect to login if user is not logged in and trying to access protected page
	if (!user && loggedIn) {
		return <Navigate to="/" replace={true} />;
	}

	return <Outlet />;
}

export default AuthGuard;
