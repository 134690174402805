import { faHistory } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useHistory from "../../common/datahooks/useHistory";
import useBreakpoints from "../../common/useBreakpoints";
import HistoryListMobile from "./HistoryListMobile";

function HistoryList() {
	const { t, i18n } = useTranslation();
	const { data: orders, isLoading } = useHistory();
	const { isMobile } = useBreakpoints();

	const lang = i18n.language;

	if (isMobile) {
		return <HistoryListMobile orders={orders} isLoading={isLoading} />;
	}

	return (
		<div className="px-16 py-8">
			<div className="flex text-grey text-3xl mb-8 items-center">
				<FontAwesomeIcon icon={faHistory} className="mr-4" />
				<div>{t("home.history.title")}</div>
			</div>
			{isLoading && <p className="text-center">{t("home.history.loading")}</p>}
			{!isLoading && orders && orders.length === 0 && (
				<p className="text-center text-grey">{t("home.history.empty")}</p>
			)}
			{!isLoading && orders && orders.length > 0 && (
				<table className="w-full">
					<thead>
						<tr className="text-grey text-sm">
							<td>{t("home.history.date")}</td>
							<td>{t("home.history.name")}</td>
							<td>{t("home.history.address")}</td>
						</tr>
					</thead>
					<tbody>
						{orders.map((order) => (
							<tr
								className="text-lg font-bold border-grey border-b"
								key={order.id}
							>
								<td className="py-5">
									{order.scheduledTime.toLocaleDateString()}
								</td>
								<td className="py-5">{order.getName(lang)}</td>
								<td className="py-5">{order.location.address}</td>
								<td>
									<Link
										to={"/order/" + order.id}
										className="text-blue py-5 block"
									>
										{t("home.history.view")}
									</Link>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			)}
		</div>
	);
}

export default HistoryList;
