import {
	faBook,
	faBuilding,
	faEnvelope,
	faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { email, phoneNumber } from "../../common/contactInfo";

function Footer() {
	const { t } = useTranslation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<footer className="bg-blue  sm:h-14 text-white px-5 py-4 flex flex-col sm:flex-row items-center justify-between bottom-0 left-0 w-full gap-4">
			<div className="flex flex-col sm:flex-row gap-2 sm:gap-10 text-sm self-start">
				<p>
					<a href={`mailto:${email}`}>
						<FontAwesomeIcon icon={faEnvelope} className="mr-2" />
						{email}
					</a>
				</p>
				<p>
					<a href={`tel:${phoneNumber}`}>
						<FontAwesomeIcon icon={faPhone} className="mr-2" />
						{phoneNumber}
					</a>
				</p>
				<p>
					<FontAwesomeIcon icon={faBuilding} className="mr-2" /> Kulturni
					majstor doo Beograd
				</p>
			</div>
			<div>
				<FontAwesomeIcon icon={faBook} className="mr-2" />
				<Link to={"/terms"} className="underline">
					{t("footer.terms")}
				</Link>
			</div>
		</footer>
	);
}

export default Footer;
