import { useTranslation } from "react-i18next";
import sideLogo from "../../assets/images/sideLogo.svg";

function Side() {
	const { t } = useTranslation();
	return (
		<div className="w-[640px] relative bg-blue">
			<img
				src={sideLogo}
				alt="sideLogo"
				className="absolute top-1/2 right-0 -translate-y-1/2 max-h-[60vh]"
			/>
			<div className="text-3xl font-medium text-white absolute left-20 right-20 bottom-20">{t("auth.side.slogan")}</div>
		</div>
	);
}

export default Side;
