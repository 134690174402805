import { useQuery } from "react-query";
import { get } from "../http";
import { Order } from "../types";
import { parseOrder } from "../parser";

async function getHistory() {
	return (await get<Order[]>("orders/history")).map((order) => {
		return parseOrder(order);
	}).sort((a, b) => {
		return b.createdAt.getTime() - a.createdAt.getTime();
	});
}

export default function useHistory() {
	const { isLoading, error, data, refetch } = useQuery<Order[]>(
		"history",
		getHistory
	);

	return {
		isLoading,
		error,
		data,
		refetch,
	};
}