import { useQuery } from "react-query";
import { get } from "../http";
import { RepairType } from "../types";
import { parseRepairType } from "../parser";

async function getRepairs() {
	return (await get<RepairType[]>("/repairs")).map((repairType) =>
		parseRepairType(repairType)
	);
}

export default function useRepairs() {
	const { isLoading, error, data, refetch } = useQuery<RepairType[]>(
		"repairs",
		getRepairs
	);

	return {
		isLoading,
		error,
		data,
		refetch,
	};
}
