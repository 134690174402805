import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { Order } from "../../common/types";
import Images from "../orderdetails/Images";
import Button from "../ui/button/Button";
import { useNavigate } from "react-router";
import useBreakpoints from "../../common/useBreakpoints";
import { useState } from "react";
import CancelPopup from "./CancelPopup";
import { AnimatePresence } from "framer-motion";
import { createPortal } from "react-dom";

function ActiveOrder({
	order,
	onCanceled,
}: {
	order: Order;
	onCanceled: () => void;
}) {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();

	const [isPopupOpen, setIsPopupOpen] = useState(false);

	const { isMobile } = useBreakpoints();

	if (isMobile) {
		return (
			<>
				{createPortal(
					<AnimatePresence>
						{isPopupOpen && (
							<CancelPopup
								onClose={setIsPopupOpen.bind(null, false)}
								order={order}
								onCanceled={onCanceled}
							/>
						)}
					</AnimatePresence>,
					document.body
				)}
				<div className="border-b-2 border-blue p-4 text-center">
					<div className="flex justify-center mb-2">
						<p className="bg-yellow text-white px-2 rounded">
							{t("home.active-order.status")}
						</p>
					</div>
					<div className="flex justify-center mb-2">
						<div className="text-blue">
							<FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
							<span>
								{t("home.active-order.scheduled-time")}
								<span className="ml-1">{order.getScheduledDateTime(t)}</span>
							</span>
						</div>
					</div>

					<h2 className="text-lg font-medium mt-1 mr-8">
						{order.getName(i18n.language || "en")}
					</h2>
					{order.repairDesc && <p>{order.repairDesc}</p>}
					{order.images.length > 0 && <Images images={order.images} />}
					<Button
						onClick={() => navigate(`/order/${order.id}`)}
						name={t("home.active-order.view-details")}
						width="full"
					/>
					<p
						className="text-red p-1 mt-3 cursor-pointer"
						onClick={setIsPopupOpen.bind(null, true)}
					>
						{t("home.active-order.cancel")}
					</p>
				</div>
			</>
		);
	}

	return (
		<>
			{createPortal(
				<AnimatePresence>
					{isPopupOpen && (
						<CancelPopup
							onClose={setIsPopupOpen.bind(null, false)}
							order={order}
							onCanceled={onCanceled}
						/>
					)}
				</AnimatePresence>,
				document.body
			)}
			<div className="border-l-2 border-blue p-4 pl-8">
				<div className="flex justify-between">
					<div className="text-blue">
						<FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
						<span>
							{t("home.active-order.scheduled-time")}
							<span className="ml-1">{order.getScheduledDateTime(t)}</span>
						</span>
					</div>
					<p className="bg-yellow text-white px-2 rounded">
						{t("home.active-order.status")}
					</p>
				</div>

				<h2 className="text-2xl font-medium mt-1 mr-8 max-w-xl">
					{order.getName(i18n.language || "en")}
				</h2>
				{order.repairDesc && <p className="max-w-2xl">{order.repairDesc}</p>}
				<div className="flex mt-4 justify-between gap-8">
					{order.images.length > 0 && <Images images={order.images} />}
					<div className="flex flex-col items-center self-end">
						<Button
							onClick={() => navigate(`/order/${order.id}`)}
							name={t("home.active-order.view-details")}
							width="big"
							className="w-[311px]"
						/>
						<p
							className="text-red p-1 mt-3 cursor-pointer"
							onClick={setIsPopupOpen.bind(null, true)}
						>
							{t("home.active-order.cancel")}
						</p>
					</div>
				</div>
			</div>
		</>
	);
}

export default ActiveOrder;
