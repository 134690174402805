function Input({
	value,
	placeholder,
	onChange,
	className = "",
	type = "text",
}: {
	type?: string;
	value: string;
	placeholder?: string;
	onChange: (value: string) => void;
	className?: string;
}) {
	return (
		<input
			type={type}
			className={`p-4 rounded-lg border border-grey w-full sm:w-[311px] hover:border-blue focus:border-blue outline-none ${className} transition-all`}
			value={value}
			placeholder={placeholder}
			onChange={(e) => onChange(e.target.value)}
		/>
	);
}

export default Input;
