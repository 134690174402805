import Row from "../dashboard/Row";
import LanguageDropdown from "../ui/language/LanguageDropdown";
import { useTranslation } from "react-i18next";

function ChangeLanguage() {
	const { t } = useTranslation();
	return (
		<Row className="sm:flex justify-between items-center">
			<div>
				<h1 className="text-black text-lg font-bold">
					{t("settings.language")}
				</h1>
			</div>
			<div className="sm:mt-0 mt-3">
				<LanguageDropdown />
			</div>
		</Row>
	);
}

export default ChangeLanguage;
