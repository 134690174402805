import { useQuery } from "react-query";
import { get } from "../http";
import { TimeSlot } from "../types";

async function getTimeSlots() {
	return (await get<TimeSlot[]>("/orders/free-time-slots")).map((source) => {
		const timeSlot = {
			from: new Date(source.from),
			to: new Date(source.to),
			text: "",
		};
		timeSlot.text = formatTimeSlot(timeSlot);
		return timeSlot;
	});
}

export default function useTimeSlots() {
	const { isLoading, error, data, refetch } = useQuery<TimeSlot[]>(
		"timeSlots",
		getTimeSlots
	);

	return {
		isLoading,
		error,
		data,
		refetch,
	};
}

function formatTimeSlot(timeSlot: TimeSlot) {
	const from = timeSlot.from.getHours().toString().padStart(2, "0");
	const to = timeSlot.to.getHours().toString().padStart(2, "0");
	return `${from}:00 - ${to}:00`;
}
