import { faCoins } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../ui/button/Button";
import { useState } from "react";
import { AdditionalCost } from "../../common/types";
import useBreakpoints from "../../common/useBreakpoints";
import { useTranslation } from "react-i18next";
import { post } from "../../common/http";
import toast from "react-hot-toast";

function AdditionalCostBanner({
	onClose,
	additionalCost,
	orderId,
}: {
	onClose: () => void;
	additionalCost: AdditionalCost;
	orderId: number;
}) {
	const [loading, setLoading] = useState<null | string>(null);
	const { isMobile } = useBreakpoints();

	const { t } = useTranslation();

	function accept() {
		setLoading("accept");
		post(`orders/${orderId}/additional-cost/${additionalCost.id}/accept`, {})
			.then(() => {
				setLoading(null);
				onClose();
			})
			.catch(() => {
				toast.error(t("home.additional-cost.failed-accept"));
			});
	}

	function decline() {
		setLoading("decline");

		post(`orders/${orderId}/additional-cost/${additionalCost.id}/reject`, {})
			.then(() => {
				setLoading(null);
				onClose();
			})
			.catch(() => {
				toast.error(t("home.additional-cost.failed-reject"));
			});
	}

	if (isMobile) {
		return (
			<div className="border-y-2 border-green bg-green/10 px-4 text-center">
				<div className="flex items-center justify-center mt-6">
					<FontAwesomeIcon icon={faCoins} className="text-green text-lg mr-2" />
					<h1 className="font-semibold text-xl">
						{t("home.additional-cost.additional-cost")} - {additionalCost.cost}{" "}
						RSD
					</h1>
				</div>
				<p className="mt-1 mb-6">{additionalCost.description}</p>
				<Button
					className="mb-3"
					name={t("home.additional-cost.accept")}
					width="full"
					background="green"
					loading={loading === "accept"}
					disabled={loading !== null}
					onClick={accept}
				/>
				<Button
					className="mb-4"
					name={t("home.additional-cost.decline")}
					width="full"
					background="black"
					loading={loading === "decline"}
					disabled={loading !== null}
					onClick={decline}
				/>
			</div>
		);
	}

	return (
		<div className="rounded-lg border-2 border-green bg-green/10 flex justify-between w-full p-6 mt-6">
			<div className="flex items-center">
				<FontAwesomeIcon
					icon={faCoins}
					className="text-green text-4xl ml-2 mr-6"
				/>
				<div>
					<h1 className="font-semibold text-xl">
						{t("home.additional-cost.additional-cost")} - {additionalCost.cost}{" "}
						RSD
					</h1>
					<p>{additionalCost.description}</p>
				</div>
			</div>
			<div className="flex gap-5">
				<Button
					name={t("home.additional-cost.accept")}
					width="big"
					background="green"
					loading={loading === "accept"}
					disabled={loading !== null}
					onClick={accept}
				/>
				<Button
					name={t("home.additional-cost.decline")}
					width="big"
					background="black"
					loading={loading === "decline"}
					disabled={loading !== null}
					onClick={decline}
				/>
			</div>
		</div>
	);
}

export default AdditionalCostBanner;
