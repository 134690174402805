import logo from "../../assets/images/logoBlue.svg";
import { motion } from "framer-motion";

function PageLoader() {
	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ ease: "easeOut", duration: 0.3 }}
			className="w-screen h-screen flex items-center justify-center fixed top-0 left-0 bg-white z-50"
		>
			<img src={logo} className="w-16 h-16" alt="logo" />
		</motion.div>
	);
}

export default PageLoader;
