import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { auth } from './firebase';

//const BASE_URL = 'http://localhost:8080/';
const BASE_URL = 'https://api.kulturnimajstor.rs/';

const http = axios.create({
    baseURL: BASE_URL,
});

http.interceptors.request.use(async (config: AxiosRequestConfig | any) => {
    const currentUser = auth.currentUser;
    if (currentUser) {
        const token = await currentUser.getIdToken();
        config.headers = {
            ...config.headers,
            Authorization: `Bearer ${token}`,
        };
    }
    return config;
});

export const get = async <T>(url: string, config?: AxiosRequestConfig): Promise<T> => {
    const response: AxiosResponse<T> = await http.get(url, config);
    return response.data;
};

export const post = async <T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> => {
    const response: AxiosResponse<T> = await http.post(url, data, config);
    return response.data;
};

export const put = async <T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> => {
    const response: AxiosResponse<T> = await http.put(url, data, config);
    return response.data;
};

export const patch = async <T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> => {
    const response: AxiosResponse<T> = await http.patch(url, data, config);
    return response.data;
};

export const del = async <T>(url: string, config?: AxiosRequestConfig): Promise<T> => {
    const response: AxiosResponse<T> = await http.delete(url, config);
    return response.data;
};
