import { useTranslation } from "react-i18next";
import Row from "../dashboard/Row";

function RepairDescription({
	setDescription,
}: {
	setDescription: (description: string) => void;
}) {
	const { t } = useTranslation();

	return (
		<Row>
			<h2 className="text-black font-bold text-lg mb-6">
				{t("new-repair.add-description")}
			</h2>
			<textarea
				className="rounded-lg p-4 border-grey w-full h-40 focus:border-blue hover:border-blue transition-[border-color] shadow-none"
				placeholder={t("new-repair.add-description")}
				onChange={(e) => setDescription(e.target.value)}
			></textarea>
		</Row>
	);
}

export default RepairDescription;
