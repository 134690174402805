import { ReactNode } from "react";
import Side from "./Side";
import useBreakpoints from "../../common/useBreakpoints";
import logo from "../../assets/images/logoBlue.svg";
import LanguageDropdown from "../ui/language/LanguageDropdownAuth";
import Footer from "../footer/Footer";

function AuthLayout({
	children,
	onSubmit,
}: {
	children: ReactNode;
	onSubmit: () => void;
}) {
	const { isDesktop } = useBreakpoints();
	return (
		<div>
			<div className="flex border-b border-blue/50">
				<div className="flex-grow sm:flex items-center justify-center min-h-screen relative">
					<div className="absolute top-3 right-3">
						<LanguageDropdown />
					</div>
					<div className="sm:w-[360px] w-full sm:m-5 px-5 py-20">
						<img src={logo} alt="logo" className="h-12 mx-auto mb-4" />
						<form onSubmit={onSubmit}>{children}</form>
					</div>
					<div className="sm:absolute w-full bottom-0">
						<Footer />
					</div>
				</div>
				{isDesktop && <Side />}
			</div>
		</div>
	);
}

export default AuthLayout;
