import { ReactNode } from "react";
import tile from "../../assets/images/tile2.png";

function TiledContainer({
	children,
	className = "",
}: {
	children: ReactNode;
	className?: string;
}) {
	return (
		<div
			className={"relative overflow-hidden border-b-2 border-blue " + className}
		>
			<div className="-z-10 absolute left-0 top-0 w-full h-full">
				<div
					className="absolute top-[-50%] left-[-50%] w-[200%] h-[200%] rotate-12 pointer-events-none"
					style={{ background: "url(" + tile + ")" }}
				></div>
				<div
					className="absolute top-0 left-0 w-full h-full bg-tile-overlay pointer-events-none"
					style={{ background: "linear-gradient(180deg, #E5F6FF, #0000)" }}
				></div>
			</div>
			{children}
		</div>
	);
}

export default TiledContainer;
