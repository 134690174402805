import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import enLocale from "./assets/locales/en.json";
import srLocale from "./assets/locales/sr.json";

i18n
	.use(initReactI18next)
	.use(LanguageDetector)
	.init({
		fallbackLng: "en",
		resources: {
			en: {
				translation: enLocale,
			},
			sr: {
				translation: srLocale,
			},
		},
	});

export default i18n;
