import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import flagEN from "../../../assets/images/flag_en.svg";
import flagSR from "../../../assets/images/flag_sr.svg";
import useBreakpoints from "../../../common/useBreakpoints";

function LanguageDropdownAuth() {
	const dropdownRef = useRef<HTMLDivElement>(null);
	const [isOpen, setOpen] = useState(false);

	const { isMobile } = useBreakpoints();

	const { i18n } = useTranslation();

	const languages = [
		{
			id: "en",
			name: "English",
			img: flagEN,
		},
		{
			id: "sr",
			name: "Srpski",
			img: flagSR,
		},
	];

	const selected =
		languages[1].id === i18n.language ? languages[1] : languages[0];
	const placeholder = "Select language";

	const handleClickOutside = (event: MouseEvent) => {
		if (
			dropdownRef.current &&
			!dropdownRef.current.contains(event.target as Node)
		) {
			setOpen(false);
		}
	};

	function onSelectItem(langugage: { id: string; name: string }) {
		i18n.changeLanguage(langugage.id);
		setOpen(false);
	}

	function toggleOpen() {
		if (languages.length === 0) return;
		setOpen((prev) => !prev);
	}

	useEffect(() => {
		document.addEventListener("click", handleClickOutside);
		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, []);

	return (
		<div className="relative text-black sm:w-36" ref={dropdownRef}>
			<div
				className="cursor-pointer border hover:border border-white sm:border-grey px-3 py-1 rounded-lg focus:border-blue hover:border-blue flex items-center justify-between hover:bg-grey/5 gap-2"
				onClick={toggleOpen}
			>
				{selected && (
					<p className="text-black flex gap-2">
						<img src={selected.img} alt="flag" className="w-6 h-6"/>
						{!isMobile && selected.name}
					</p>
				)}
				{!selected && placeholder && <p className="text-grey">{placeholder}</p>}
				<FontAwesomeIcon icon={faAngleDown} className="text-black" />
			</div>
			{isOpen && (
				<div className="border rounded-lg absolute top-full translate-y-2 w-full overflow-hidden z-10">
					{languages.map((option, index) => (
						<div
							className="font-medium text-black px-3 py-1 hover:bg-grey/10 cursor-pointer flex gap-2 items-center sm:justify-start justify-center"
							key={index}
							onClick={onSelectItem.bind(null, option)}
						>
							<img src={option.img} alt="flag" className="w-6 h-6"/>
							{!isMobile && option.name}
						</div>
					))}
				</div>
			)}
		</div>
	);
}

export default LanguageDropdownAuth;
