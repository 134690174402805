import { useContext, useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Button from "../ui/button/Button";
import { UserContext } from "../../stores/userContext";
import { auth } from "../../common/firebase";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import UserImg from "../ui/UserImg";

function AccountDropdown() {
	const dropdownRef = useRef<HTMLDivElement>(null);
	const [isOpen, setIsOpen] = useState(false);
	const user = useContext(UserContext);
	const navigate = useNavigate();
	const { t } = useTranslation();

	const handleClickOutside = (event: MouseEvent) => {
		if (
			dropdownRef.current &&
			!dropdownRef.current.contains(event.target as Node)
		) {
			setIsOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener("click", handleClickOutside);
		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, []);

	const toggle = () => setIsOpen(!isOpen);

	function onLogout() {
		auth.signOut().then(() => {
			navigate("/");
		});
	}

	function onSettings() {
		navigate("/settings");
		setIsOpen(false);
	}

	if (!user) return <></>;

	return (
		<div ref={dropdownRef} className="relative">
			<div className="h-12 w-12 rounded-full cursor-pointer" onClick={toggle}>
				<UserImg />
			</div>
			<AnimatePresence>
				{isOpen && (
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						className="absolute top-full right-0 translate-y-2 rounded-lg border border-grey bg-white p-4 min-w-[300px] z-10"
					>
						<div className="flex justify-start align-center whitespace-nowrap">
							<div className="h-12 w-12">
								<UserImg />
							</div>
							<div className="ml-3">
								<p className="font-bold">{user.displayName}</p>
								<p>{user.email}</p>
							</div>
						</div>
						<div className="flex mt-3 gap-3.5">
							<Button
								name={t("home.header.settings")}
								height="small"
								onClick={onSettings}
							/>
							<Button
								name={t("home.header.log-out")}
								height="small"
								background="black"
								onClick={onLogout}
							/>
						</div>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
}

export default AccountDropdown;
