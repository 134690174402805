import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Storage } from "aws-amplify";
import CircleLoader from "../../common/loaders/CircleLoader";
import { UserContext } from "../../stores/userContext";
import reduce from "image-blob-reduce";
import Row from "../dashboard/Row";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faTrash } from "@fortawesome/free-solid-svg-icons";

function Images({
	addImage,
	removeImage,
}: {
	addImage: (image: string) => void;
	removeImage: (image: string) => void;
}) {
	const { t } = useTranslation();
	const [images, setImages] = useState<Image[]>([]);
	const user = useContext(UserContext);

	async function onChange(e: React.ChangeEvent<HTMLInputElement>) {
		if (!e.target.files) {
			return;
		}
		const files = Array.from(e.target.files);
		const images = files.map((file) => ({
			file,
		}));
		for (let i = 0; i < images.length; i++) {
			const image = images[i];
			const name = image.file.name;
			image.file = new File([await compressImage(image)], name);
		}
		setImages((prev) => [...prev, ...images]);
		uploadImages(images);
	}

	function uploadImages(images: Image[]) {
		images.forEach(async (image) => {
			const name = image.file.name;
			try {
				const result = await Storage.put(user.uid + "_" + name, image.file, {
					contentType: image.file.type,
				});
				const src =
					"https://repairimagesdev-dev.s3.eu-west-2.amazonaws.com/public/" +
					result.key;
				addImage(src);
				setImages((prev) =>
					prev.map((image) => {
						if (image.file.name === name) {
							return {
								...image,
								src,
								key: result.key,
							};
						}
						return image;
					})
				);
			} catch (err) {
				console.log("Error uploading file: ", err);
			}
		});
	}

	async function compressImage(image: Image) {
		const reduceInstance = reduce();
		const file = await reduceInstance.toBlob(image.file, { max: 800 });
		return file;
	}

	function onRemoveClick(image: Image) {
		if (!image.src || !image.key) {
			return;
		}
		Storage.remove(image.key).then(() => {});
		removeImage(image.src);
		setImages((prev) =>
			prev.filter((prevImage) => prevImage.key !== image.key)
		);
	}

	function getImagesView() {
		return (
			<div className="photos mt-6">
				<p className="text-black ">
					{t("new-repair.photos", {
						count: images.length,
					})}
				</p>
				<div className="flex flex-wrap gap-4 mt-4">
					{images.map((image) => (
						<div>
							<div
								className="relative w-20 h-20 bg-grey rounded flex justify-center items-center overflow-hidden"
								key={image.file.name}
							>
								{image.src && (
									<div className="bg-gradient-to-b from-black/60 absolute left-0 top-0 w-full h-1/4 flex justify-end">
										<FontAwesomeIcon
											icon={faClose}
											onClick={onRemoveClick.bind(null, image)}
											className="text-white cursor-pointer w-4 h-4 block mt-[2px] mr-[2px]"
										/>
									</div>
								)}
								{image.src && (
									<img
										className="max-w-20 max-h-20"
										src={image.src}
										alt="repair"
									/>
								)}
								{!image.src && <CircleLoader className="center-absolute" />}
							</div>
						</div>
					))}
				</div>
			</div>
		);
	}

	return (
		<Row>
			<div className="flex flex-col sm:flex-row justify-between sm:items-center w-full">
				<h1 className="text-black font-bold text-lg mb-2 sm:mb-0">
					{t("new-repair.add-image")}
				</h1>
				<label
					className="border border-blue rounded-lg relative cursor-pointer py-4 text-blue w-full sm:w-[311px] text-center"
					htmlFor="images-input"
				>
					{t("new-repair.upload-photos")}
				</label>
				<input
					id="images-input"
					type="file"
					multiple
					accept="image/*"
					onChange={onChange}
					className="hidden"
					title="test"
				/>
			</div>
			{images.length > 0 && getImagesView()}
		</Row>
	);
}

export default Images;

export type Image = {
	file: File;
	src?: string;
	key?: string;
};
