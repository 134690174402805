import CircleLoader from "../../../common/loaders/CircleLoader";

function Button({
	name,
	onClick,
	background = "blue",
	color = "white",
	type = "button",
	width = "full",
	height = "big",
	blueShadow = false,
	className = "",
	loading = false,
	disabled = false,
}: {
	name: string;
	type?: "button" | "submit" | "reset";
	background?: string;
	color?: string;
	height?: "big" | "small";
	width?: "full" | "big";
	blueShadow?: boolean;
	onClick?: () => void;
	className?: string;
	loading?: boolean;
	disabled?: boolean;
}) {
	//backgroud: transparent, yellow, blue, purple, red, green
	const bgClass = "bg-" + background;

	//color: white, black
	const colorClass = "text-" + color;

	//width: full, big
	let widthClass = "w-full";
	if (width === "big") {
		widthClass = `w-[200px]`;
	}

	//height: big, small
	let heightClass = "h-[52px]";
	if (height === "small") {
		heightClass = "h-8";
	}

	return (
		<button
			className={`rounded-lg flex items-center justify-center ${bgClass} ${colorClass} ${widthClass} ${heightClass} ${className} hover:brightness-95`}
			style={{
				boxShadow: blueShadow ? "0 0 0 4px #41a4dc4d, 0 0 0 8px #41a4dc1a" : "",
			}}
			type={type}
			onClick={onClick}
			disabled={disabled}
		>
			{!loading && name}
			{loading && <CircleLoader scale="0.9"/>}
		</button>
	);
}

export default Button;
