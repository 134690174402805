import Footer from "../footer/Footer";
import Header from "./Header";

function Terms() {
	return (
		<div>
			<Header />
			<body className="c16 c18 py-10 px-10 sm:px-20">
				<p className="text-center mb-6">
					<span className="text-center text-2xl font-semibold">
						USLOVI KORI&Scaron;&#262;ENJA
					</span>
				</p>
				<p className="mt-3 c15">
					<span className="c3 c10"></span>
				</p>
				<p className="mt-3">
					<span className="c0">
						Uslovi kori&scaron;&#263;enja reguli&scaron;u pristup i pravila
						kori&scaron;&#263;enja usluga i aplikacije, kao i prava i obaveze
						korisnika i pru&#382;aoca usluga Kulturni Majstor koji je u
						vlasni&scaron;tvu Kulturni majstor DOO Beograd (u daljem tekstu:
						Kulturni majstor), PIB: 113902181, MB: 21942308 sa sedi&scaron;tem u
						Beogradu, ulica Vladimira Rolovi&#263;a 168.&nbsp;
					</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						U saradnji sa privrednim dru&scaron;tvom Facility Point DOO Beograd,
						&nbsp;koje pru&#382;a neophodan materijal za izvo&#273;enje radova,
						Kulturni Majstor pru&#382;a usluge korisnicima kojima su potrebne
						popravke ili odr&#382;avanje domova ili preduzec&#769;a (privrednih
						dru&scaron;tva), na prakti&#269;an, pouzdan i jednostavan
						na&#269;in.
					</span>
				</p>
				<p className="c13">
					<span className="c2 c3">
						Kulturni Majstor posluje u skladu sa propisima Republike Srbije koji
						obuhvataju Zakon o elektronskoj trgovini, Zakon o obligacionim
						odnosima, Zakon o za&scaron;titi potro&scaron;a&#269;a, Zakon o
						za&scaron;titi podataka o li
					</span>
					<span className="c2">&#269;nosti</span>
					<span className="c0">
						, Zakon o ogla&scaron;avanju i ostale relevantne propise.
					</span>
				</p>
				<p className="mt-3">
					<span className="c2 c3">
						Na&scaron; tim obu&#269;enih i iskusnih servisera je na raspolaganju
						za obavljanje &scaron;irokog spektra poslova, od manjih popravki do
						velikih renoviranja. Usluge servisa Kulturn iMajstor uklju&#269;uju,
						ali nisu ograni&#269;ene na popravke ure&#273;aja,
						vodoinstalaterske, elektri&#269;arske, stolarske radove,
						kerami&#269;arske ili druge op&scaron;te usluge.
					</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						Molimo Vas da pa&#382;ljivo pro&#269;itate Uslove
						kori&scaron;&#263;enja pre registracije ili kori&scaron;&#263;enja
						usluga i aplikacije Kulturni Majstor.&nbsp;
					</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						Ovi uslovi kori&scaron;&#263;enja predstavljaju ugovor po pristupu
						izme&#273;u pru&#382;aoca usluge Kulturni Majstor i korisnika.
						Kori&scaron;&#263;enjem aplikacije izjavljujete da ste upoznati i da
						se sla&#382;ete sa pravima i obavezama navedenim u Uslovima
						kori&scaron;&#263;enja.
					</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						Ukoliko kao korisnik niste saglasni sa pravima i obavezama koje
						proisti&#269;u iz ovih Uslova kori&scaron;&#263;enja, molimo Vas da
						ne koristite aplikaciju i usluge servisa Kulturni Majstor, odnosno
						kori&scaron;&#263;enje aplikacije &#263;e biti
						onemogu&#263;eno.&nbsp;
					</span>
				</p>
				<p className="mt-3">
					<span className="c2 c3">
						Prihvatanjem ovih Uslova kori&scaron;&#263;enja prestaju da
						va&#382;e svi prethodni ugovori,{" "}
					</span>
					<span className="c2 c3 c16">pisani ili usmeni </span>
					<span className="c2 c3">sporazumi</span>
					<span className="c2 c3 c16">
						&nbsp;koji ovde nisu posebno pomenuti i to{" "}
					</span>
					<span className="c0">bilo koje vrste.</span>
				</p>
				<p className="mt-3">
					<span className="c3 font-medium">Pristup Aplikaciji</span>
				</p>
				<p className="mt-3">
					<span className="c2 c3">
						Kori&scaron;&#263;enje aplikacije Kulturni Majstor podrazumeva
						registraciju korisnika.
					</span>
				</p>
				<p className="mt-3">
					<span className="c2 c3">
						Korisnik se u okviru aplikacije registruje i pravi svoj
						korisni&#269;ki nalog za&scaron;ti&#263;en lozinkom, pru&#382;anjem
						osnovnih informacija o sebi kao &scaron;to su ime i prezime,
						e-po&scaron;ta i{" "}
					</span>
					<span className="c2">lozinka</span>
					<span className="c2 c3">&nbsp;korisnika.&nbsp;</span>
				</p>
				<p className="mt-3">
					<span className="c2 c3">
						Nakon unosa podataka, validnost korisni&#269;kog naloga verifikuje
						se putem e-po&scaron;te odnosno broja telefona koje je korisnik
						prethodno uneo.&nbsp;
					</span>
				</p>
				<p className="mt-3">
					<span className="c2 c3">
						Usluge i aplikacija Kulturni Majstor namenjeni su osobama koje imaju
						najmanje 18 godina i poslovnu sposobnost da sklapaju ugovore.
						Kori&scaron;c&#769;enjem aplikacije, korisnik izjavljuje i garantuje
						da ispunjava ove uslove.
					</span>
				</p>
				<p className="mt-3">
					<span className="c2 c3">
						Korisnik garantuje da su podaci koje unosi u aplikaciju prilikom
						registracije ta&#269;ni, aktuelni i potpuni, i obave
					</span>
					<span className="c2">zuje se</span>
					<span className="c0">
						&nbsp;da &#263;e ih blagovremeno a&#382;urirati. Kulturni Majstor ne
						garantuje za ta&#269;nost podataka unetih od strane korisnika.&nbsp;
					</span>
				</p>
				<p className="mt-3">
					<span className="c2 c3">
						U okviru svog korisni&#269;kog naloga, korisnik ima pristup svim
						informacijama o izvedenim radovima, sa detaljnim opisom radova i
						materijala, cenama, kao i informacije o garanciji na pojedina dobra
						i usluge.&nbsp;
					</span>
				</p>
				<p className="mt-3">
					<span className="c2 c3">
						Korisnik je isklju&#269;ivo odgovoran za odr&#382;avanje bezbednosti
						i poverljivosti svog korisni&#269;kog naloga i saglasan je da bez
						odlaganja obavesti servis Kulturni Majstor o svakom
						neovla&scaron;&#263;enom kori&scaron;&#263;enju naloga.&nbsp;
					</span>
				</p>
				<p className="mt-3">
					<span className="c2 c3">
						Korisnik je isklju&#269;ivo odgovoran servisu Kulturni Majstor, kao
						i svim tre&#263;im licima za sve aktivnosti na njegovom nalogu koje
						su posledica neovla&scaron;&#263;enog kori&scaron;&#263;enja
						naloga.&nbsp;
					</span>
				</p>
				<p className="mt-3">
					<span className="c2 c3">
						Korisnik je isklju&#269;ivo odgovoran za &#269;uvanje sopstvene
						lozinke.&nbsp;
					</span>
				</p>
				<p className="mt-3">
					<span className="c2 c3">
						Servis Kulturni Majstor zadr&#382;ava pravo da prilikom registracije
						prikuplja odre&#273;ene li&#269;ne podatke i informacije o
						na&#269;inu pla&#263;anja usluga.&nbsp;&nbsp;
					</span>
				</p>
				<p className="mt-3">
					<span className="font-medium mt-4 c3">
						Kori&scaron;&#263;enje aplikacije i usluga servisa Kulturni Majstor
					</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						Kori&scaron;&#263;enje aplikacije Kulturni Majstor olak&scaron;ava
						proces pronala&#382;enja kvalitetnog majstora, planiranja termina i
						tro&scaron;kova odre&#273;enog posla. Ove usluge mogu biti osnovne
						popravke i teku&#263;a odr&#382;avanja, ali i specijalizovane usluge
						i usluge ve&#263;eg obima (intesticiona odr&#382;avanja i dr.).
					</span>
				</p>
				<p className="mt-3">
					<span className="c2 c3">
						Koristec&#769;i aplikaciju Kulturni Majstor korisnici mogu lako
						ugovoriti uslugu popravke ili odr&#382;avanja tako &scaron;to
						c&#769;e se registovati u skladu sa Uslovima kori&scaron;&#263;enja
						i nakon toga izabrati vrstu usluge koja im je potrebna.
					</span>
				</p>
				<p className="mt-3">
					<span className="c2 c3">
						Korisniku je u okviru aplikacije dostupna lista usluga koje
						pru&#382;a servis Kulturni Majstor, od kojih korisnik bira
						odgovaraju&#263;u, nakon &#269;ega pru&#382;a dodatni opis
						problema.&nbsp;
					</span>
				</p>
				<p className="mt-3">
					<span className="c2 c3">
						Radi pravilnog utvr&#273;ivanja opsega neophodnih radova ali i cene
						usluge, korisnik je obavezan da uz kratak opis problema prilo&#382;i
						i{" "}
					</span>
					<span className="c2">fotografije</span>
					<span className="c2 c3">.&nbsp;</span>
				</p>
				<p className="mt-3 mt-3">
					<span className="font-medium c3"></span>
				</p>
				<p className="mt-3 mt-3">
					<span className="font-medium c3"></span>
				</p>
				<p className="mt-3 mt-3">
					<span className="font-medium c3"></span>
				</p>
				<p className="mt-3 mt-3">
					<span className="font-medium c3"></span>
				</p>
				<p className="mt-3">
					<span className="font-medium mt-4 c3">Dostavljanje ponude</span>
				</p>
				<p className="mt-3">
					<span className="c2 c3">
						Nakon &scaron;to je korisnik uneo sve neophodne parametre,{" "}
					</span>
					<span className="c2 c3 c9">na&scaron; tim </span>
					<span className="c2 c3">kroz aplikaciju </span>
					<span className="c2 c3 c9">
						pru&#382;a korisniku jasnu i transparentnu procenjenu vrednost
						radova za izabranu uslugu, koja mo&#382;e dodatno da uklju&#269;uje
						i vrednost potrebnog materijala.
					</span>
				</p>
				<p className="mt-3">
					<span className="c2 c3 c9">
						Istovremeno, korisniku &#263;e biti dostupan i pregled slobodnih
						termina majstora servisa Kulturni Majstor od kojih bira
						odgovaraju&#263;i.&nbsp;
					</span>
				</p>
				<p className="mt-3">
					<span className="c2 c3">
						Ukoliko pristaje na ponudu servisa Kulturni Majstor,{" "}
					</span>
					<span className="c2 c3 c9">korisnik </span>
					<span className="c2 c3">
						je prihvata isklju&#269;ivo kroz aplikaciju.
					</span>
				</p>
				<p className="mt-3">
					<span className="c2 c3">
						Po izlasku na teren, majstor &#263;e ponovo izvr&scaron;iti procenu
						problema (radova, materijala i dr). Ukoliko majstor, u toku
						rada,&nbsp; na&#273;e da opis neophodnog posla nije bio
						odgovaraju&#263;i ili je zbog same prirode problema neophodno
						obaviti i dodatne radove, odnosno ukoliko postoji potreba za
						pro&scaron;irenjem posla, kao i u slu&#269;aju da majstor nije u
						mogu&#263;nosti da re&scaron;i problem korisnika, o tome &#263;e
						odmah putem telefona obavestiti nadle&#382;no lice servisa Kulturni
						Majstor i korisnika neposredno, usmenim putem.
					</span>
				</p>
				<p className="mt-3">
					<span className="c2 c3">
						Po prijemu obave&scaron;tenja, tim servisa Kulturni Majstor &#263;e
						kroz aplikaciju predstaviti novo re&scaron;enje, odnosno
						dostavi&#263;e novu ponudu, prilago&#273;enu potrebama korisnika.
					</span>
				</p>
				<p className="mt-3">
					<span className="c2 c3">
						Ukoliko je korisnik sa novom ponudom saglasan istu prihvata putem
						aplikacije
					</span>
					<span className="c2">. T</span>
					<span className="c2 c3">
						ek nakon prihvatanja nove ponude ugovor se smatra zaklju&#269;enim i
						pristupa se daljim radovima.
					</span>
				</p>
				<p className="mt-3">
					<span className="c2 c3">
						Servis Kulturni Majstor &#263;e korisnicima dostavljati ponude
						isklju&#269;ivo putem aplikacije Kulturni Majstor. Svaka evenutalna
						promena u ceni &#263;e korisniku biti prezentovana u pisanoj formi
						putem aplikacije, i na istu &#263;e korisnik morati izri&#269;ito da
						pristane.&nbsp;
					</span>
				</p>
				<p className="mt-3">
					<span className="c2 c3">
						Usluge servisa Kulturni Majstor nije mogu&#263;e ugovoriti usmeno
						ili drugim putem, sem kroz dostavljanje obrazlo&#382;ene ponude
						servisa kroz aplikaciju Kulturni Majstor.&nbsp;
					</span>
				</p>
				<p className="mt-3">
					<span className="c2 c3">
						Ponuda u&#269;injena kroz aplikaciju smatra se ponudom
						u&#269;injenom neposredno prisutnom licu i smatra se odbijenom
						ukoliko nije prihva&#263;ena bez odlaganja.
					</span>
				</p>
				<p className="mt-3">
					<span className="c2 c3">
						Servis Kulturni Majstor zadr&#382;ava pravo da produ&#382;i ili
						skrati trajanje ponude u svakom konkretnom slu&#269;aju.&nbsp;
					</span>
				</p>
				<p className="mt-3">
					<span className="c2 c3">
						Prihvatanjem ponude dostavljene kroz aplikaciju ugovor o delu
						izme&#273;u korisnika i servisa Kulturni Majstor se smatra
						zaklju&#269;enim.&nbsp;
					</span>
				</p>
				<p className="mt-3 mt-3">
					<span className="c3 font-medium"></span>
				</p>
				<p className="mt-3 mt-3">
					<span className="font-medium c3"></span>
				</p>
				<p className="mt-3">
					<span className="font-medium mt-4 c3">
						Dostavljanje ponude za radove ve&#263;eg obima
					</span>
				</p>
				<p className="mt-3">
					<span className="c2 c3">Pored usluga manjeg obima </span>
					<span className="c2">koje su dostupne</span>
					<span className="c2 c3">
						&nbsp;kroz aplikaciju, korisnicima aplikacije Kulturni Majstor
						dostupna je mogu&#263;nost&nbsp; jednostavnog dogovaranja projekata
						renoviranja i adaptacije bilo{" "}
					</span>
					<span className="c2">kog</span>
					<span className="c2 c3">&nbsp;</span>
					<span className="c2">obima</span>
					<span className="c2 c3">,</span>
					<span className="c2">&nbsp;</span>
					<span className="c2 c3">
						kao &scaron;to su potpuna renoviranja, kre&#269;enja, farbanje
						stolarije, &nbsp;zamena parketa, zamena i postavljanje prozora i
						vrata (pregradnih i ulaznih) zamena i lakiranje stolarije, zamena
						keramike i sanitarija, &nbsp;pravljenje kuhinja i sli&#269;no, a u
						skladu sa potrebama&nbsp; i bud&#382;etom korisnika.&nbsp;&nbsp;
					</span>
				</p>
				<p className="mt-3">
					<span className="c2 c3">
						U tom slu&#269;aju korisnik &#263;e putem aplikacije imati opciju da
						zaka&#382;e procenu i davanje ponude za radove ve&#263;eg
						obima.&nbsp;
					</span>
				</p>
				<p className="mt-3" id="h.gjdgxs">
					<span className="c0">
						Servis Kulturni Majstor &#263;e nakon sagledavanja celokupnog obima
						posla, korisniku dostaviti ponudu sa predvi&#273;enim rokom
						va&#382;enja same ponude ali i cenom, rokom za zavr&scaron;etak
						radova, kao i svim neophodnim elementima za zaklju&#269;enje
						ugovora.&nbsp;
					</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						Ukoliko korisnik prihvati ponudu servisa Kulturni Majstor,
						dostavi&#263;e mu se predlog ugovora o delu koji zaklju&#269;uje sa
						servisom Kulturni Majstor, nakon &#269;ega &#263;e biti du&#382;an
						da plati avans za &#382;eljene radove.
					</span>
				</p>
				<p className="mt-3">
					<span className="font-medium mt-4 c3">Ugovor o delu</span>
					<span className="c0">&nbsp;</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						Zaklju&#269;enjem ugovora o delu, naru&#269;ilac posla (korisnik) i
						poslenik (servis Kulturni Majstor), shodno Zakonu o obligacionim
						odnosima preuzimaju na sebe odre&#273;ene obaveze kojih su
						du&#382;ni da se pridr&#382;avaju. Na sve &scaron;to ugovorom nije
						predvi&#273;eno, &nbsp;primeni&#263;e se odredbe ZOO.
					</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						Ugovor pored osnovnih podataka o naru&#269;iocu posla i posleniku,
						datuma zaklju&#269;enja ugovora, obavezno sadr&#382;i i opis
						konkretnog posla koji je izvr&scaron;ilac posla du&#382;an da obavi
						u odre&#273;enom roku za potrebe naru&#269;ioca i na&#269;in
						obavljanja ugovorenog posla, vrednost posla i na&#269;in
						pla&#263;anja, &nbsp;kvalitet materijala, pravo naru&#269;ioca da
						nadzire obavljanje posla i daje uputstva posleniku, rokove za
						izvr&scaron;enje posla, slu&#269;ajeve za raskid ugovora.
					</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						Ugovor o delu mo&#382;e da sadr&#382;i i druge elemente.
					</span>
				</p>
				<p className="mt-3">
					<span className="c2 c3">
						Kulturni Majstor se obavezuje da korisnicima obezbedi i neophodne
						materijale i delove &nbsp;srednjeg kvaliteta u smislu{" "}
					</span>
					<span className="c2">Zakona o obligacionim odnosima </span>
					<span className="c0">
						ukoliko nije druga&#269;ije predvi&#273;eno odredbama Ugovora.
						&nbsp;
					</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						Obaveze poslenika i naru&#269;ioca u smislu ZOO su:
					</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						Kulturni Majstor se obavezuje da izvr&scaron;i delo kako je
						ugovoreno i po pravilima posla, da uka&#382;e na nedostatke
						materijala, ukoliko je korisnik odnosno naru&#269;ilac dao materijal
						neophodan za obavljanje posla, da izvr&scaron;i posao za ugovoreno
						vreme, a ako ono nije odre&#273;eno, onda za vreme koje je razumno
						potrebno za takve poslove, da se pridr&#382;ava uslova iz ugovora,
						po&scaron;tuje rokove, odgovara naru&#269;iocu za izvr&scaron;enje
						posla i kad posao ne obavi li&#269;no, odgovara za lica koja su po
						njegovom nalogu radila na poslu koji je preuzeo da izvr&scaron;i,
						kao da ga je s&acirc;m izvr&scaron;io, preda naru&#269;iocu
						izra&#273;enu ili opravljenu stvar a osloba&#273;a se ove obaveze
						ako stvar koju je izradio ili opravio propadne iz uzroka za koji on
						ne odgovara.
					</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						Ako je naru&#269;ilac zahtevao da se stvar izradi od materijala na
						&#269;ije mu je nedostatke poslenik ukazao, poslenik je du&#382;an
						postupiti po njegovom zahtevu, izuzev ako je o&#269;igledno da
						materijal nije podoban za naru&#269;eno delo ili ako bi izrada od
						zahtevanog materijala mogla naneti &scaron;tetu ugledu poslenika, u
						kom slu&#269;aju poslenik mo&#382;e raskinuti ugovor. Poslenik je
						du&#382;an upozoriti naru&#269;ioca na nedostatke u njegovom nalogu,
						kao i na druge okolnosti za koje je znao ili je morao znati, koje
						mogu biti od zna&#269;aja za naru&#269;eno delo ili za njegovo
						izvr&scaron;enje na vreme, ina&#269;e &#263;e odgovarati za
						&scaron;tetu.
					</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						S druge strane, naru&#269;ilac, odnosno u konkretnom slu&#269;aju
						poslodavac, je du&#382;an primiti rad izvr&scaron;en prema odredbama
						ugovora i pravilima posla, isplatiti ugovorenu naknadu koja je
						odre&#273;ena ugovorom.
					</span>
				</p>
				<p className="mt-3">
					<span className="font-medium mt-4 c3">Zavr&scaron;etak radova</span>
				</p>
				<p className="mt-3">
					<span className="c2 c3">Nakon </span>
					<span className="c2">zavr&scaron;etka</span>
					<span className="c0">
						&nbsp;radova nezavisno od obima, majstor &#263;e odmah obavestiti
						nadle&#382;no lice servisa Kulturni Majstor i dostaviti kratak opis
						izvr&scaron;enih aktivnosti, kao i fotografije.&nbsp;
					</span>
				</p>
				<p className="mt-3">
					<span className="c2 c3">
						Dokumentacija o izvr&scaron;enim radovima &#263;e biti{" "}
					</span>
					<span className="c2">dostupna</span>
					<span className="c0">
						&nbsp;u okviru korisni&#269;kog naloga pojedina&#269;nog korisnika u
						okviru aplikacije Kulturni Majstor, a kojoj korisnik ima pristup,
						tako da odmah ima uvid u opis obavljenih radova.&nbsp;
					</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						Pored toga, majstor &#263;e sastaviti i pismeni radni nalog koji
						korisnik neposredno potpisuje i time izjavljuje da su radovi
						obavljeni u svemu u skladu sa ugovorom.&nbsp;
					</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						Ukoliko korisnik ne&#263;e da potpi&scaron;e, onemogu&#263;ena nam
						je naplata. &nbsp;
					</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						Ukoliko korisnik odbije da potpi&scaron;e pisani radni nalog
						majstora, a ne stavi primedbe u pogledu koli&#269;ine izvedenih
						radova, na pisani radni nalog u prisustvu majstora, smatra&#263;e se
						da je primopredaja izvedenih radova izvr&scaron;ena, a Kulturni
						majstor sti&#269;e pravo da fakturi&scaron;e svoje usluge.
					</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						Servis Kulturni Majstor &#263;e korisniku dostaviti i pismeni
						izve&scaron;taj o radovima putem adrese e-po&scaron;te koju je
						korisnik uneo prilikom registracije, u kom trenutku &#263;e
						korisniku biti poslate instrukcije za pla&#263;anje. Pla&#263;anje
						je takodje mogu&#263;e izvr&scaron;iti i u gotovom novcu, &scaron;to
						&#263;e biti evidentirano kroz samu aplikaciju.&nbsp;
					</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						Korisniku &#263;e se nakon izvr&scaron;enog pla&#263;anja dostaviti
						fiskalni ra&#269;un na adresu e-po&scaron;te koju je korisnik uneo
						prilikom registracije.&nbsp;
					</span>
				</p>
				<p className="mt-3">
					<span className="font-medium mt-4 c3">Hitne intervencije</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						Servis Kulturni Majstor nudi mogu&#263;nost pru&#382;anja usluga i u
						slu&#269;aju hitnih intervencija.&nbsp;
					</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						U tom slu&#269;aju, korisnik &#263;e kroz aplikaciju obavestiti
						servis Kulturni Majstor da je u pitanju hitna intervencija i kroz
						aplikaciju &#263;e mu biti dostavljena ponuda za zaklju&#269;enje
						ugovora, odnosno bi&#263;e obave&scaron;ten o ceni usluge.&nbsp;
					</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						U slu&#269;aju potrebe za hitnom intervencijom majstor servisa
						Kulturni Majstor &#263;e na tra&#382;enoj lokaciji biti u roku od 2
						sata od momenta evidentiranja prihvatanja ponude u sistemu Kulturni
						Majstor.&nbsp;
					</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						Kulturni Majstor ne garantuje korisnicima za mogu&#263;nost
						kori&scaron;&#263;enja usluge hitnih intervencija.
					</span>
				</p>
				<p className="mt-3">
					<span className="font-medium mt-4 c3">Odustajanje</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						Korisnik ima pravo da odustane od ugovora u bilo kom trenutku, pod
						uslovima i na&#269;in predvi&#273;en u ovim Uslovima
						kori&scaron;&#263;enja.&nbsp;
					</span>
				</p>
				<p className="mt-3">
					<span className="c2 c3">
						Ukoliko korisnik otka&#382;e uslugu pre izlaska majstora na teren i
						to u roku od 4 &#269;asa od momenta re
					</span>
					<span className="c2">zervacije</span>
					<span className="c0">
						, to mo&#382;e u&#269;initi bez pla&#263;anje naknade servisu
						Kulturni Majstor.&nbsp;
					</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						Nakon proteka 4 &#269;asa od rezervacije usluge, korisniku &#263;e
						biti napla&#263;ena naknada za otkazivanje u iznosu od 1.500,00
						dinara. Ovaj iznos &#263;e Korisniku biti fakturisan po istom
						principu kao i pla&#263;anje uspe&scaron;no zavr&scaron;ene usluge.
					</span>
				</p>
				<p className="mt-3">
					<span className="c2 c3">
						U slu&#269;aju da majstor stigne na dogovorenu lokaciju, a korisnik
						ne omogu&#263;i majstoru pristup i izvo&#273;enje radova, usluga
						&#263;e se smatrati otkazanom, a korisniku &#263;e biti{" "}
					</span>
					<span className="c2">napla&#263;ena</span>
					<span className="c2 c3">&nbsp;</span>
					<span className="c2">nadoknada za izgubljeno vreme.</span>
					<span className="c2 c12">&nbsp;</span>
					<span className="c0">
						Slu&#382;bena bele&scaron;ka na servisnom nalogu kojom majstor
						konstatuje da mu nije odobren pristup smatra&#263;e se dokazom da
						korisnik nije ispunio svoju obavezu.
					</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						Prilikom prihvatanja ponude za hitne intervencije, rok za
						odustajanje od ugovora iznosi 60 sekundi od momenta prihvatanja
						ponude. Za svako odustajanje odnosno otkazivanje usluge nakon isteka
						perioda od 60 sekundi korisniku &#263;e biti napla&#263;ena naknada
						za odustanak u iznosu od 1.500,00 dinara pre izlaska na lice mesta
						odnosno naknada za izgubljeno vreme (i trosak dolaska) u
						slu&#269;aju da majstor stigne na dogovorenu lokaciju, a korisnik ne
						omogu&#263;i majstoru pristup i izvo&#273;enje radova.
					</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						Od momenta prihvatanja ponude pa sve do zavr&scaron;etka posla,
						korisnik mo&#382;e raskinuti ugovor u kom slu&#269;aju je du&#382;an
						isplatiti celokupnu ugovorenu naknadu za rad i materijal.
					</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						Kulturni Majstor zadr&#382;ava pravo da&nbsp; iznos naknade za
						otkazivanje izmeni uz obavezu obave&scaron;tavanja korisnika u
						skladu sa Uslovima poslovanja.&nbsp;
					</span>
				</p>
				<p className="mt-3 mt-3">
					<span className="c8 c2"></span>
				</p>
				<p className="mt-3 mt-3">
					<span className="c0"></span>
				</p>
				<p className="mt-3">
					<span className="font-medium c3">Reklamacije i prigovori</span>
				</p>
				<p className="mt-3">
					<span className="c2 c3">
						U slu&#269;aju postojanja nesaobraznosti u pogledu ugovorene usluge
						korisnik mo&#382;e izjaviti{" "}
					</span>
					<span className="c2">reklamaciju</span>
					<span className="c0">
						&nbsp;u roku od 48 &#269;asova od momenta zavr&scaron;etka posla
						slanjem mejla na adresu: office@kulturnimajstor.rs.
					</span>
				</p>
				<p className="mt-3">
					<span className="c2 c3">
						Nakon procene situacije od strane tima Kulturni Majstor korisnik
						&#263;e biti obave&scaron;ten o tome da li je reklamacija usvojena
						ili ne
					</span>
					<span className="c0">
						, a najkasnije u roku od 15 dana od dana prijema reklamacije. Nakon
						prijema odgovora, korisnik je du&#382;an da se najkasnije u roku od
						3 dana od dana kada je putem aplikacije obave&scaron;ten o odgovoru
						izjasni o re&scaron;enju koje je ponudio Kulturni Majstor. U
						suprotnom smatra se da nije saglasan sa predlo&#382;enom ponudom.
					</span>
				</p>
				<p className="mt-3">
					<span className="c2 c12">&nbsp;</span>
					<span className="c0">
						Ukoliko je odmah prilikom preliminarne procene utvr&#273;eno da je
						prigovor o&#269;igledno opravdan nadle&#382;ni majstori &#263;e
						iza&#263;i na teren i otkloniti nepravilnost, uz saglasnost
						korisnika.
					</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						Korisnik je du&#382;an da majstoru obezbedi neophodan pristup.
					</span>
				</p>
				<p className="mt-3">
					<span className="c2 c3">
						Ukoliko Kulturni Majstor odmah ne usvoji prigovor korisnika, na
						teren &#263;e iza&#263;i nadle&#382;ni majstor servisa Kulturni
						Majstor koji &#263;e izvr&scaron;iti procenu situacije,{" "}
					</span>
					<span className="c2">izvr&scaron;iti uvidj</span>
					<span className="c2 c3">&nbsp;i utvrditi da li je </span>
					<span className="c2">reklamacija</span>
					<span className="c0">&nbsp;opravdana ili ne. </span>
				</p>
				<p className="mt-3">
					<span className="c0">
						U slu&#269;aju da korisnik ne odobri pristup predstavniku servisa
						Kulturni Majstor smatra&#263;e se da je od reklamacije odustao.
					</span>
				</p>
				<p className="mt-3">
					<span className="c2 c3">Ukoliko se </span>
					<span className="c2">r</span>
					<span className="c0">
						eklamacija korisnika ne usvaja Kulturni Majstor &#263;e korisniku
						dostaviti obrazlo&#382;en odgovor najkasnije u roku od 15 dana od
						dana izjavljivanja reklamacije.
					</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						U pogledu reklamacija primenjuju se odredbe Zakona o za&scaron;titi
						potro&scaron;a&#269;a i Zakona o obligacionim odnosima.
					</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						U pogledu kvaliteta izvedenih radova Kulturni Majstor odgovara za
						kvalitet i kvantitet izvedenih radova u skladu sa ugovorom, dok za
						kvalitet nabavljene i ugra&#273;ene opreme odgovora Facility point
						doo, PIB:111704644 MB: 21525588.
					</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						Shodno Zakonu o za&scaron;titi potro&scaron;a&#269;a,
						potro&scaron;a&#269; ima mogu&#263;nost vansudskog re&scaron;avanja
						potro&scaron;a&#269;kog spora.
					</span>
				</p>
				<p className="mt-3 mt-3">
					<span className="c2 c8"></span>
				</p>
				<p className="mt-3">
					<span className="font-medium mt-4 c3">Pla&#263;anje </span>
				</p>
				<p className="mt-3">
					<span className="c0">
						Usluge Kulturni Majstor mogu&#263;e je platiti e bankingom ili
						gotovinom u valuti Republike Srbije, dinar RSD, u visini
						nazna&#269;enoj u ponudi koju je korisnik prihvatio.
					</span>
				</p>
				<p className="mt-3">
					<span className="c2 c3">
						Kulturni Majstor korisniku upu&#263;uje jedinstvenu ponudu sa
						ura&#269;unatom cenom rada i materijala za izabranu uslugu i{" "}
					</span>
					<span className="c2">materijal</span>
					<span className="c0">
						. Naplata cene usluge i materijala nakon izvr&scaron;enih radova, na
						ime cene rada i izvr&scaron;ene usluge vr&scaron;i se u korist i za
						ra&#269;un servisa Kulturni Majstor, dok se cena materijala
						napla&#263;uje uplatom na ra&#269;un Facility point doo
						Beograd.&nbsp;
					</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						Usluge servisa Kulturni Majstor mogu&#263;e je platiti u gotovini
						ili putem aplikacije.
					</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						Kada korisnik vr&scaron;i pla&#263;anja putem aplikacije Kulturni
						Majstor, to mo&#382;e u&#269;initi putem CorvusPay sistema za
						sigurno pla&#263;anje platnim karticama preko interneta. Kada
						korisnik pokrene pla&#263;anje, bi&#263;e preusmeren na formular za
						pla&#263;anje CorvusPay. Od trenutka kada korisnik unosi informacije
						o platnoj kartici, CorvusPay obezbe&#273;uje potpunu privatnost
						podataka. Poverljive informacije se prenose putem javne mre&#382;e u
						za&scaron;ti&#263;enoj (kriptovanoj) formi putem SSL protokola,
						koriste&#263;i najnaprednije metode tokenizacije osetljivih
						podataka, u skladu sa PCI-DSS standardima. Va&#382;no je napomenuti
						da ni u jednom trenutku podaci o platnoj kartici nisu dostupni
						aplikaciji Kulturni Majstor.
					</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						Bezbednost i privatnost podataka o pla&#263;anju tokom transakcija
						koje uklju&#269;uju CorvusPay je isklju&#269;ivo odgovornost Corvus
						Pay, kao tre&#263;eg lica i servis Kulturni Majstor nema pristup
						osetljivim podacima platnoj kartici u bilo kom trenutku tokom
						procesa. Kori&scaron;&#263;enjem aplikacije Kulturni Majstor i
						vr&scaron;enjem pla&#263;anja putem CorvusPay-a, priznajete i
						prihvatate uslove kori&scaron;&#263;enja, mere bezbednosti i
						standarde koje je implementirao CorvusPay kako bi za&scaron;titio
						va&scaron;e informacije o pla&#263;anju.
					</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						Servis Kulturni Majstor ne vr&scaron;i rezervciju sredstava pre
						po&#269;etka dogovorenih radova, ve&#263; se pla&#263;anje
						vr&scaron;i tek nakon obavljene usluge, unosa opisa radova u bazu
						podataka i potpisivanja radnog naloga od strane korisnika.&nbsp;
					</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						Isklju&#269;ivo u slu&#269;aju radova velikog obima, korisnik
						&#263;e biti obavezan da uplati avans u iznosu koji &#263;e biti
						obra&#269;unat i predvi&#273;en u ponudi. Iznos avansa se
						ura&#269;unava u iznos ukupne cene usluge.
					</span>
				</p>
				<p className="mt-3 mt-3">
					<span className="c8 c2"></span>
				</p>
				<p className="mt-3 mt-3">
					<span className="c2 c12 c17"></span>
				</p>
				<p className="c11">
					<span className="font-medium c3">Povra&#263;aj sredstava</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						Povra&#263;aj sredstava korisniku (i to samo u slu&#269;aju
						slu&#269;aju povra&#263;aja avansa ili ako to bude predlo&#382;eno u
						odgovoru na reklamaciju) &nbsp;se vr&scaron;i koriste&#263;i ista
						sredstva pla&#263;anja koja je potro&scaron;a&#269; koristio u
						prvobitnoj transakciji. U slu&#269;aju povra&#263;aja novca
						korisniku koji je uslugu platio u gotovini ili uplatom na
						ra&#269;un, Kulturni Majstor &#263;e povrat novca izvr&scaron;iti
						uplatom na ra&#269;un koji mu korisnik dostavi, dok isplata u
						gotovini nije mogu&#263;a.
					</span>
				</p>
				<p className="mt-3">
					<span className="font-medium c3">Ograni&#269;enje odgovornosti</span>
				</p>
				<p className="mt-3">
					<span className="c2 c3">
						Kori&scaron;c&#769;enjem aplikacije, potvr&#273;ujete i sla&#382;ete
						se da na dostupnost, funkcionalnost i performanse aplikacije
						uti&#269;u eksterni faktori uklju&#269;ujuc&#769;i radnje i
						u&#269;inak nezavisnih tre&#263;ih lica. Kulturni Majstor ne snosi
						odgovornosti i ne garantuje za eventualne tehni&#269;ke probleme ili
						na bilo koji na&#269;in za proizvode i usluge koja pru&#382;aju
						tre&#263;a lica, koja nisu ugovorne strane u ovom ugovor
					</span>
					<span className="c2">nom odnosu</span>
					<span className="c2 c3">
						. Stoga, ne mo&#382;emo biti odgovorni za bilo kakve smetnje,
						gre&scaron;ke ili ograni&#269;enja koja su uzrokovana uslugama
						trec&#769;ih strana kao &scaron;to su pru&#382;aoci internet usluga,
						mobilnih operativnih sistema, payment{" "}
					</span>
					<span className="c2">gateway-a</span>
					<span className="c0">&nbsp;itd. </span>
				</p>
				<p className="mt-3">
					<span className="c0">
						Nastojimo da odr&#382;imo najvi&scaron;i nivo dostupnosti i
						pouzdanosti za aplikaciju i povezane usluge. Me&#273;utim, zbog
						inherentne prirode digitalnih usluga i slo&#382;enosti tehnologije,
						Kulturni Majstor ne garantuje neprekidan pristup aplikaciji kao ni
						neprekidnost u kori&scaron;&#263;enju usluga. Razli&#269;iti
						faktori, uklju&#269;ujuc&#769;i rutinsko odr&#382;avanje,
						neo&#269;ekivani tehni&#269;ki problemi ili drugi spoljni faktori
						mogu dovesti do prekida ili ograni&#269;enog pristupa aplikaciji.
						Kulturni Majstor ne mo&#382;e se smatrati odgovornim za bilo kakve
						gubitke, &scaron;tete ili neprijatnosti koje proisti&#269;u iz ovih
						prekida. Dostupnost aplikacije mo&#382;e biti podlo&#382;na
						promenama usled razli&#269;itih faktora kao &scaron;to su
						tehni&#269;ki problemi, odr&#382;avanje sistema, nadogradnje ili
						drugi nepredvi&#273;eni doga&#273;aji. Kulturni Majstor ne garantuje
						da &#263;e aplikacija uvek biti dostupna niti da &#263;e biti
						dostupna bez prekida ili gre&scaron;aka. U slu&#269;aju da
						predvidimo ili prepoznamo odstupanja u dostupnosti aplikacije koja
						mogu trajati du&#382;e ili biti zna&#269;ajna, obavesti&#263;emo
						korisnike putem odgovaraju&#263;ih kanala komunikacije, odnosno
						e-po&scaron;tom i telefonom.
					</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						Kulturni Majstor ne odgovara za bilo kakav eventualni gubitak ili
						&scaron;tetu koja bi mogla proiza&#263;i iz prenosa virusa,
						skrivenih nedostataka, gre&scaron;aka prekida, brisanja, kvara,
						napada uskra&#263;ivanja usluge ili drugih tehnolo&scaron;ki
						&scaron;tetnih elemenata. Ovi faktori mogu potencijalno inficirati
						va&scaron;u elektronsku i mobilnu opremu, ra&#269;unarske programe,
						podatke ili druge resurse tokom kori&scaron;&#263;enja aplikacije.
						Tako&#273;e, ne snosimo odgovornost za eventualnu &scaron;tetu
						uzrokovanu preuzimanjem sadr&#382;aja sa aplikacije ili sa bilo koje
						povezane veb lokacije. Kulturni Majstor ne&#263;e biti smatran
						odgovornim pod bilo kojim okolnostima za &scaron;tetu koja
						proizilazi iz slede&#263;ih situacija: smanjenje funkcionalnosti ili
						prestanak rada aplikacije ili usluga, &nbsp;izjave ili radnje
						tre&#263;ih strana koje su povezane sa aplikacijom ili uslugom;
						direktne interakcije i transakcije izme&#273;u vas i tre&#263;ih
						strana ili pru&#382;alaca usluga.
					</span>
				</p>
				<p className="mt-3">
					<span className="font-medium c3">
						Zloupotreba i Odgovornost za Korisni&#269;ki Sadr&#382;aj:
					</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						Eksplicitno je zabranjena svaka zloupotreba usluga i aplikacije
						Kulturni Majstor (uklju&#269;uju&#263;i i poku&scaron;aje hakovanja,
						prodiranja u za&scaron;ti&#263;ene delove servisa, nedozvoljeno
						kori&scaron;&#263;enje podataka i onemogu&#263;avanje drugih
						korisnika u kori&scaron;&#263;enju aplikacije)
					</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						Kori&scaron;&#263;enjem aplikacije i usluga Kulturni Majstor
						obavezujete se da &#263;ete se pridr&#382;avate odredbi Uslova
						kori&scaron;&#263;enja. Obavezujete se da se pona&scaron;ate uljudno
						prema pru&#382;aocima usluga i svim drugim prisutnim licima.
						Preuzimate obavezu da va&scaron;e kori&scaron;&#263;enje aplikacije
						i usluga bude u skladu sa zakonima i regulativama Republike Srbije.
						Zabranjeno je unositi, deliti ili propagirati sadr&#382;aj koji je:
						ilegalan, uvredljiv ili neprikladan. Strogo je zabranjeno
						postavljanje sadr&#382;aja koji kr&scaron;i doma&#263;e i
						me&#273;unarodne zakone, intelektualnu svojinu tre&#263;ih lica,
						sadr&#382;aj koji dovodi u zabludu, sadr&#382;aj sa reklamnim
						karakterom, viruse ili bilo koji potencijalno &scaron;tetan
						sadr&#382;aj. Sav korisni&#269;ki generisan sadr&#382;aj koji
						postavite uklju&#269;uju&#263;i utiske, fotografije i izjave,
						postaju neekskluzivno vlasni&scaron;tvo aplikacije Kulturni Majstor.
						Imamo pravo da koristimo ovaj sadr&#382;aj, uklju&#269;uju&#263;i
						njegovo prilago&#273;avanje u svrhu promocije ili drugih potreba,
						bez ograni&#269;enja.
					</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						U slu&#269;aju kr&scaron;enja odredaba Uslova
						kori&scaron;&#263;enja, Kulturni Majstor ima pravo da preduzme mere
						kao &scaron;to su privremena suspenzija ili trajna deaktivacija
						korisni&#269;kog naloga, kao i pokretanje odgovaraju&#263;ih
						postupaka. Kr&scaron;enje ovih smernica zahteva nadoknadu platformi
						Kulturni Majstor &nbsp;za sve &scaron;tete, obaveze, tro&scaron;kove
						i gubitke koje pretrpi usled radnji korisnika.
					</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						Korisnik se obavezuje da &#263;e &#269;uvati tajnost svojih
						korisni&#269;kih kredencijala, ne&#263;e ih deliti sa drugima i
						ne&#263;e ovlastiti tre&#263;a lica da koriste njegov
						korisni&#269;ki nalog. Tako&#273;e, u nekim slu&#269;ajevima,
						Kulturni Majstor mo&#382;e zatra&#382;iti dokaz o identitetu
						korisnika kako bismo pru&#382;ili uslugu, a odbijanje mo&#382;e
						rezultirati uskra&#263;ivanjem pristupa.
					</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						Kulturni Majstor ima pravo da uskrati servis korisnicima bez najave
						i obrazlo&#382;enja, po sopstvenoj proceni, a pogotovo ako se
						korisnici la&#382;no predstavljaju, prave la&#382;na zakazivanja I
						postupaju suprotno Uslovima kori&scaron;&#263;enja.
					</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						Nije dozvoljeno pravljenje korisni&#269;kih naloga putem
						automatizovanih programa ili poku&scaron;aja prijavljivanja kroz
						automatizovane metode. Va&scaron;a odgovornost je odr&#382;avanje
						sigurnost va&scaron;eg korisni&#269;kog naloga i lozinke. Ne
						mo&#382;emo preuzeti odgovornost za eventualne gubitke ili
						&scaron;tetu proiza&scaron;lu iz nepo&scaron;tovanja ove sigurnosne
						obaveze.
					</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						KulturniMajstor zadr&#382;ava puno pravo da u bilo kom momentu
						prekine pru&#382;anje Usluga sa trenutnim dejstvom, deaktiviranjem
						naloga ili na drugi na&#269;in spre&#269;avanjem pristupa ili
						kori&scaron;c&#769;enja aplikacije, po sopstvenom naho&#273;enju.
					</span>
				</p>
				<p className="mt-3 mt-3">
					<span className="font-medium c3"></span>
				</p>
				<p className="mt-3">
					<span className="font-medium c3">Autorska prava</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						Kori&scaron;&#263;enje servisa Kulturni Majstor dozvoljeno je
						isklju&#269;ivo u li&#269;ne i nekomercijalne svrhe, osim ukoliko
						druga&#269;ije nije ugovoreno.
					</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						Zabranjeno je svako neovla&scaron;&#263;eno kori&scaron;&#263;enje,
						umno&#382;avanje, modifikacija aplikacije ili dela aplikacija ili
						logoa, &#382;iga, znaka ili bilo koje oznake autorskih ili srodnih
						prava iz bilo kog dela aplikacije, kao i svaka vrsta komercijalne
						eksploatacije aplikacije Kulturni Majstor.
					</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						Zabranjeno je svako modifikovanje, menjanje ili pravljenje
						derivativnih verzija aplikacije, vr&scaron;enje
						neovla&scaron;&#263;enog pristupa aplikaciji, kao i svako ometanje
						rada i funkcionalnosti rada servisa i aplikacije i povezanih
						sistema.
					</span>
				</p>
				<p className="mt-3">
					<span className="font-medium c3">
						Komunikacija I obave&scaron;tenja
					</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						Dodatna komunikacija sa korisnikom se, u slu&#269;aju potrebe,
						vr&scaron;i putem adrese elektronske po&scaron;te i putem telefona
						kako bi mu se dostavila obave&scaron;tenja, dokumenta, ponude i
						druge informacije u vezi sa kori&scaron;&#263;enjem servisa,
						statusom naloga i zakazivanja, kao I radi dobijanja &nbsp;povratne
						informacije od korisnika &nbsp;o radu aplikacije u svrhu
						pobolj&scaron;anja usluge.
					</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						&nbsp;KulturniMajstor nije i ne mo&#382;e biti odgovoran u
						slu&#269;aju neuspele dostave na adresu elektronske po&scaron;te ili
						na bilo koji drugi na&#269;in.
					</span>
				</p>
				<p className="mt-3">
					<span className="font-medium c3">Re&scaron;avanje sporova</span>
				</p>
				<p className="mt-3">
					<span className="c2 c3">
						&nbsp;Ukoliko sporazumno re&scaron;avanje spora nije mogu&#263;e, za
						re&scaron;avanje sporova bi&#263;e nadle&#382;an sud u Beogradu.
					</span>
				</p>
				<p className="mt-3">
					<span className="font-medium c3">
						Trajanje i prestanak kori&scaron;&#263;enja usluga i Uslova
						kori&scaron;&#263;enja
					</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						Uslovi kori&scaron;&#263;enja va&#382;e od: 15.10.2023. godine
					</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						Do momenta stupanja na snagu izmena primenjiva&#263;e se prethodno
						va&#382;e&#263;i Uslovi kori&scaron;&#263;enja.
					</span>
				</p>
				<p className="mt-3">
					<span className="c0">
						Kulturni Majstor zadr&#382;ava puno pravo da promeni Uslove
						kori&scaron;&#263;enja, cenovnik i servis u bilo kom trenutku, koje
						promene &#263;e biti istaknute u okviru aplikacije.
					</span>
				</p>
				<p className="c14 mt-3">
					<span className="c0"></span>
				</p>
				<p className="mt-3 c14">
					<span className="c0"></span>
				</p>
			</body>
			<Footer />
		</div>
	);
}

export default Terms;
