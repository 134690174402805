import TitleBox from "./TitleBox";
import { Order } from "../../common/types";
import Images from "./Images";
import Button from "../ui/button/Button";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Details from "./Details";
import Paid from "./Paid";
import useBreakpoints from "../../common/useBreakpoints";
import DetailsMobile from "./DetailsMobile";

function OrderDetails({ order }: { order: Order }) {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { isMobile } = useBreakpoints();

	function onBack() {
		navigate("/home");
	}

	if (isMobile) {
		return (
			<div className="p-4">
				<TitleBox order={order} />
				<DetailsMobile order={order} />
				<Images images={order.images} />
				<div className="mb-4">
					{order.paidAmount > 0 && <Paid order={order} />}
				</div>
				<Button
					name={t("orderdetails.back")}
					onClick={onBack}
					background="black"
					width="full"
				/>
			</div>
		);
	}

	return (
		<div className="flex p-16 gap-24 justify-between max-w-[1500px] mx-auto">
			<div className="flex flex-col justify-between min-w-[30%]">
				<TitleBox order={order} />
				<div className="mt-10">
					<Images images={order.images} />
					<Button
						className="max-w-[280px]"
						name={t("orderdetails.back")}
						onClick={onBack}
						background="black"
					/>
				</div>
			</div>
			<div className="flex flex-grow max-w-2xl flex-col">
				<Details order={order} />
				{order.paidAmount > 0 && <Paid order={order} />}
			</div>
		</div>
	);
}

export default OrderDetails;
