import React, { useEffect } from "react";
import "./App.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";
import "./i18n";
import HomePage from "./pages/HomePage";
import { QueryClient, QueryClientProvider } from "react-query";
import PageLoader from "./common/loaders/PageLoader";
import OrderDetailsPage from "./pages/OrderDetailsPage";
import NewRepairPage from "./pages/NewRepairPage";
import { auth } from "./common/firebase";
import { UserContext } from "./stores/userContext";
import { User } from "firebase/auth";
import { AnimatePresence } from "framer-motion";
import AuthGuard from "./components/guards/AuthGuard";
import Settings from "./components/settings/Settings";
import Dashboard from "./components/dashboard/Dashboard";
import DeleteAccount from "./components/delete-account/DeleteAccount";
import { Toaster } from "react-hot-toast";
import ForgotPassword from "./pages/ForgotPassword";
import Terms from "./components/terms/Terms";

function App() {
	const [isLoading, setIsLoading] = React.useState(true);
	const [user, setUser] = React.useState<User | null>(null);
	const router = createBrowserRouter([
		{
			path: "delete-account",
			element: <DeleteAccount />,
		},
		{
			path: "terms",
			element: <Terms/>,
		},
		{
			path: "",
			element: <AuthGuard loggedIn={false} />,
			children: [
				{
					index: true,
					element: <Login />,
				},
				{
					path: "signup",
					element: <Register />,
				},
				{
					path: "forgot-password",
					element: <ForgotPassword />,
				},
			],
		},
		{
			path: "",
			element: <AuthGuard loggedIn={true} />,
			children: [
				{
					path: "",
					element: <Dashboard />,
					children: [
						{
							path: "home",
							element: <HomePage />,
						},
						{
							path: "order/:orderId",
							element: <OrderDetailsPage />,
						},
						{
							path: "loader",
							element: <PageLoader />,
						},
						{
							path: "schedule",
							element: <NewRepairPage />,
						},
						{
							path: "settings",
							element: <Settings />,
						},
					],
				},
			],
		},
	]);

	useEffect(() => {
		auth.onAuthStateChanged((user) => {
			setIsLoading(false);
			setUser(user);
		});
	}, []);

	const queryClient = new QueryClient();

	function getContent() {
		if (isLoading) {
			return <PageLoader key={"loader"} />;
		} else {
			if (user) {
				return (
					<UserContext.Provider value={user} key={"user-provicder"}>
						<QueryClientProvider client={queryClient}>
							<React.StrictMode>
								<Toaster
									toastOptions={{
										position: "top-right",
									}}
								/>
								<RouterProvider router={router} />
							</React.StrictMode>
						</QueryClientProvider>
					</UserContext.Provider>
				);
			} else {
				return (
					<QueryClientProvider client={queryClient}>
						<React.StrictMode>
							<Toaster
								toastOptions={{
									position: "top-right",
								}}
							/>
							<RouterProvider router={router} />
						</React.StrictMode>
					</QueryClientProvider>
				);
			}
		}
	}

	return <AnimatePresence>{getContent()}</AnimatePresence>;
}

export default App;
