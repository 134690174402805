import { useState } from "react";
import logo from "../../assets/images/logo.svg";
import useBreakpoints from "../../common/useBreakpoints";
import AccountDropdown from "./AccountDropdown";
import HamburgerMenu from "./HamburgerMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { AnimatePresence } from "framer-motion";

function Header() {
	const { isMobile } = useBreakpoints();
	const [open, setOpen] = useState(false);

	if (isMobile) {
		return (
			<div className="bg-blue flex h-16 justify-center items-center px-[60px] sm:justify-between">
				<FontAwesomeIcon
					icon={faBars}
					size="xl"
					className="text-white absolute left-5"
					onClick={() => setOpen(true)}
				/>
				<img src={logo} alt="logo" className="h-8" />
				<AnimatePresence>
					{open && <HamburgerMenu onClose={setOpen.bind(null, false)} />}
				</AnimatePresence>
			</div>
		);
	}

	return (
		<div className="bg-blue flex h-20 justify-center items-center px-[60px] sm:justify-between">
			<img src={logo} alt="logo" className="h-12" />
			<AccountDropdown />
		</div>
	);
}

export default Header;
