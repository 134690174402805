import { useContext } from "react";
import { UserContext } from "../../stores/userContext";

function UserImg() {
	const user = useContext(UserContext);

	if (user.photoURL) {
		return (
			<img
                alt="profile"
				src={user.photoURL}
				referrerPolicy="no-referrer"
				className="rounded-full w-full h-full"
			/>
		);
	}

	const letter = (user.displayName && user.displayName[0]) || "A";
	return (
		<div className="w-full h-full rounded-full bg-black flex justify-center items-center">
			<p className="text-white text-2xl">{letter}</p>
		</div>
	);
}

export default UserImg;
