import { useTranslation } from "react-i18next";
import TiledContainer from "../../common/tiledContainer/TiledContainer";
import Button from "../ui/button/Button";
import HistoryList from "./HistoryList";
import TiledContainerTitle from "../../common/tiledContainer/TiledContainerTitle";
import TiledContainerSubtitle from "../../common/tiledContainer/TiledContainerSubtitle";
import { useNavigate } from "react-router-dom";
import useActiveRepairOrder from "../../common/datahooks/useActiveRepairs";
import ActiveOrder from "./ActiveOrder";
import useBreakpoints from "../../common/useBreakpoints";
import AdditionalCostBanner from "./AdditionalCostBanner";
import { useEffect, useState } from "react";
import { AdditionalCost } from "../../common/types";
import toast from "react-hot-toast";

function Home() {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const { isMobile } = useBreakpoints();

	const { data: activeOrder, refetch } = useActiveRepairOrder();

	const [additionalCost, setAdditionalCost] = useState<null | AdditionalCost>(
		null
	);

	function onSchedule() {
		navigate("/schedule");
	}

	function onCanceled() {
		toast.success(t("home.active-order.order-canceled"));
		refetch();
	}

	const justify = activeOrder ? "justify-evenly" : "justify-center";

	useEffect(() => {
		const additionalCost = activeOrder?.additionalCosts?.find(
			(cost) => !cost.accepted && !cost.rejected
		);
		if (additionalCost) {
			setAdditionalCost(additionalCost);
		}
	}, [activeOrder]);

	return (
		<>
			<TiledContainer className="sm:px-14">
				{additionalCost && (
					<AdditionalCostBanner
						orderId={activeOrder?.id || 0}
						additionalCost={additionalCost}
						onClose={setAdditionalCost.bind(null, null)}
					/>
				)}
				<div
					className={`flex items-center sm:${justify} sm:gap-${
						activeOrder ? 8 : 0
					} px-4 sm:px-10 sm:p-8`}
				>
					<div className="max-w-[424px] flex flex-col items-center text-center py-8 sm:py-16">
						<TiledContainerTitle
							text={t("home.new-repair.title")}
							className="mb-3"
						/>
						<TiledContainerSubtitle
							text={t("home.new-repair.body")}
							className="mb-6"
						/>
						{!activeOrder && (
							<Button
								name={t("home.new-repair.button")}
								width="big"
								blueShadow
								onClick={onSchedule}
							/>
						)}
					</div>
					{activeOrder && !isMobile && (
						<ActiveOrder order={activeOrder} onCanceled={onCanceled} />
					)}
				</div>
			</TiledContainer>
			{activeOrder && isMobile && (
				<ActiveOrder order={activeOrder} onCanceled={onCanceled} />
			)}
			<HistoryList />
		</>
	);
}

export default Home;
