import { useTranslation } from "react-i18next";
import Input from "../ui/input/Input";
import { useEffect, useState } from "react";
import Row from "../dashboard/Row";
import useUserInfo from "../../common/datahooks/useUserInfo";

function AddPhone({
	setPhoneNumber,
}: {
	setPhoneNumber: (phone: string) => void;
}) {
	const { t } = useTranslation();
	const [phone, setPhone] = useState<string>("");

	const { data: userInfo } = useUserInfo();

	useEffect(() => {
		if (userInfo?.phoneNumber) {
			setPhone(userInfo.phoneNumber);
			setPhoneNumber(userInfo.phoneNumber);
		}
	}, [userInfo]);

	function onPhoneChange(value: string) {
		setPhone(value);
		setPhoneNumber(value);
	}

	return (
		<Row>
			<div className="sm:flex justify-between items-center">
				<h1 className="text-black font-bold text-lg mb-2 sm:mb-0">
					{t("new-repair.phone")}
				</h1>
				<Input
					type="tel"
					placeholder={t("new-repair.add-phone")}
					onChange={onPhoneChange}
					value={phone}
				/>
			</div>
		</Row>
	);
}

export default AddPhone;
