import { SubmitHandler, useForm } from "react-hook-form";
import AuthLayout from "../auth/AuthLayout";
import AuthInput from "../ui/input/AuthInput";
import Button from "../ui/button/Button";
import { useState } from "react";
import { get, del } from "../../common/http";
import { useNavigate, useSearchParams } from "react-router-dom";

function DeleteAccount() {
	type Inputs = {
		email: string;
	};

	const { register, handleSubmit } = useForm<Inputs>();

	const [result, setResult] = useState<string | null>(null);

	const [loading, setLoading] = useState<boolean>(false);

	const [deleting, setDeleting] = useState<boolean>(false);

	const navegate = useNavigate();

	const onSubmit: SubmitHandler<Inputs> = (data) => {
		const { email } = data;
		setLoading(true);
		get("users/request-delete?email=" + email)
			.then(() => {
				setResult(
					`Link za brisanje naloga je poslat na ${email}, molimo pratite link za brisanje naloga.`
				);
			})
			.catch((err) => {
				const message = err.response?.data?.message;
				if (message === "User not found.") {
					setResult("Email adresa nije pronađena.");
				} else if (message) {
					setResult(message);
				} else {
					setResult("Nešto je pošlo po zlu. Molimo pokušajte ponovo.");
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const [searchParams] = useSearchParams();
	const token = searchParams.get("token");

	function deleteAccount() {
		setDeleting(true);
		del("users/delete?token=" + token)
			.then(() => {
				setResult(`Vaš nalog je uspešno obrisan.`);
			})
			.catch(() => {
				setResult("Nešto je pošlo po zlu. Molimo pokušajte ponovo.");
			})
			.finally(() => {
				setDeleting(false);
			});
	}

	if (token && !result) {
		return (
			<AuthLayout onSubmit={handleSubmit(deleteAccount)}>
				<h1 className="text-center text-3xl mb-1">Brisanje Naloga</h1>
				<p className="text-center mb-6 text-sm">
					Da li ste sigurni da želite da obrišete nalog?
				</p>
				<Button
					name="Obriši Nalog"
					width="full"
					background="red"
					className="mt-4"
					type="submit"
					loading={deleting}
				/>
				<Button
					name="Nazad"
					width="full"
					className="mt-4"
					type="button"
					onClick={() => navegate("/")}
				/>
			</AuthLayout>
		);
	}

	if (result) {
		return (
			<AuthLayout onSubmit={handleSubmit(onSubmit)}>
				<h1 className="text-center text-3xl mb-1">Brisanje Naloga</h1>
				<p className="border-2 rounded-lg border-blue bg-blue/10 p-4 mt-4">
					{result}
				</p>
			</AuthLayout>
		);
	}

	return (
		<AuthLayout onSubmit={handleSubmit(onSubmit)}>
			<h1 className="text-center text-3xl mb-1">Brisanje Naloga</h1>
			<p className="text-center mb-6 text-sm">
				Unesite email adresu naloga za brisanje
			</p>
			<AuthInput
				type="email"
				register={register("email")}
				label="Email"
				name="email"
				autoFocus={true}
				error={undefined}
			/>
			<Button
				name="Obriši Nalog"
				width="full"
				className="mt-4"
				type="submit"
				loading={loading}
			/>
		</AuthLayout>
	);
}

export default DeleteAccount;
