import { useQuery } from "react-query";
import { get } from "../http";
import { UserInfo } from "../types";

async function getUserInfo() {
	return await get<UserInfo>("/users/user-info");
}

export default function useUserInfo() {
	const { isLoading, error, data, refetch } = useQuery<UserInfo>(
		"userInfo",
		getUserInfo
	);

	return {
		isLoading,
		error,
		data,
		refetch,
	};
}
