import { IdTokenResult, User } from "firebase/auth";
import { createContext } from "react";

const defaultUser: User = {
    emailVerified: false,
    isAnonymous: false,
    metadata: {
        creationTime: '',
        lastSignInTime: ''
    },
    providerData: [],
    refreshToken: "",
    tenantId: null,
    delete: function (): Promise<void> {
        throw new Error("Function not implemented.");
    },
    getIdToken: function (forceRefresh?: boolean | undefined): Promise<string> {
        throw new Error("Function not implemented.");
    },
    getIdTokenResult: function (forceRefresh?: boolean | undefined): Promise<IdTokenResult> {
        throw new Error("Function not implemented.");
    },
    reload: function (): Promise<void> {
        throw new Error("Function not implemented.");
    },
    toJSON: function (): object {
        throw new Error("Function not implemented.");
    },
    displayName: null,
    email: null,
    phoneNumber: null,
    photoURL: null,
    providerId: "",
    uid: ""
};

export const UserContext = createContext<User>(defaultUser);
