import classes from "./CircleLoader.module.css";

function CircleLoader({
	className,
	scale = "1",
}: {
	className?: string;
	scale?: string;
}) {
	return (
		<div className={classes["lds-ring"] + " " + className} style={{ scale }}>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	);
}

export default CircleLoader;
