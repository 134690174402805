import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Dropdown, { DropdownOption } from "../ui/input/Dropdown";
import Input from "../ui/input/Input";
import Row from "../dashboard/Row";
import { userInfo } from "os";
import useUserInfo from "../../common/datahooks/useUserInfo";
import { use } from "i18next";

function ChooseLocation({
	setLocation,
}: {
	setLocation: (location: LocationType) => void;
}) {
	const { t } = useTranslation();
	const [selected, setSelected] = useState<DropdownOption | null>(null);
	const [apartment, setApartment] = useState<string>("");
	const { data: userInfo } = useUserInfo();

	const options = [
		{
			text: "BW Terra",
			lat: 44.808732,
			lon: 20.45022,
		},
		{
			text: "BW Aria",
			lat: 44.808815,
			lon: 20.451515,
		},
		{
			text: "BW Vista",
			lat: 44.807769,
			lon: 20.450218,
		},
		{
			text: "BW Parkview",
			lat: 44.80707,
			lon: 20.45125,
		},
	];

	useEffect(() => {
		if (userInfo?.locationAddress) {
			const option = options.find(
				(option) => option.text === userInfo.locationAddress
			);
			if (option) {
				setSelected(option);
				if (userInfo?.apartment) {
					setApartment(userInfo.apartment);
				}
				setLocation({
					apartment: userInfo.apartment || "",
					address: userInfo.locationAddress,
					lat: (option as any).lat || 0,
					lon: (option as any).lon || 0,
				});
			}
		}
	}, [userInfo]);

	function onSelect(option: DropdownOption) {
		setSelected(option);

		setLocation({
			apartment: apartment,
			address: option.text,
			lat: (option as any).lat || 0,
			lon: (option as any).lon || 0,
		});
	}

	function onApartmantChange(value: string) {
		setApartment(value);

		setLocation({
			apartment: value,
			address: selected?.text || "",
			lat: (selected as any)?.lat || 0,
			lon: (selected as any)?.lon || 0,
		});
	}

	return (
		<Row>
			<div className="sm:flex justify-between items-center">
				<h1 className="text-black font-bold text-lg mb-2 sm:mb-0">
					{t("new-repair.location")}
				</h1>
				<div>
					<Dropdown
						placeholder={t("new-repair.choose-location")}
						selected={selected}
						options={options}
						onSelectedChange={onSelect}
					/>
				</div>
			</div>
			<div className="flex justify-end">
				<Input
					className="mt-6"
					placeholder={t("new-repair.floor-apartmant-number")}
					value={apartment}
					onChange={onApartmantChange}
				/>
			</div>
		</Row>
	);
}

export default ChooseLocation;

export type LocationType = {
	apartment: string;
	address: string;
	lat: number;
	lon: number;
};
