import { Order, RepairType } from "./types";

export function parseOrder(data: Order) {
	function getPaidAmount() {
		return data.payments.reduce((acc, curr) => {
			return acc + curr.amount;
		}, 0);
	}
	return {
		...data,
		repair: {
			...data.repair,
			createdAt: new Date(data.repair.createdAt),
			updatedAt: new Date(data.repair.updatedAt),
		},
		createdAt: new Date(data.createdAt),
		updatedAt: new Date(data.updatedAt),
		scheduledTime: new Date(data.scheduledTime),
		getName: (lang: string) => {
			if (lang === "sr") {
				return `${data.repair.categorySR} | ${data.repair.nameSR}`;
			} else {
				return `${data.repair.categoryEN} | ${data.repair.nameEN}`;
			}
		},
		getScheduledDateTime: (t: (id: string) => string) => {
			if (data.urgent) {
				return t("common.urgent");
			}
			if (!data.scheduledTime) {
				return "";
			}
			const scheduledTime = new Date(data.scheduledTime);
			const time = scheduledTime
				.toLocaleString(undefined, {
					hour: "numeric",
					minute: "numeric",
					hour12: true,
				})
				.toUpperCase()
				.replace(" ", "");
			return `${formatDate(scheduledTime)} | ${time}`;
		},
		paidAmount: getPaidAmount(),
	};
}

export function parseRepairType(repairType: RepairType): RepairType {
	console.log(repairType);
	return {
		...repairType,
		createdAt: new Date(repairType.createdAt),
		updatedAt: new Date(repairType.updatedAt),
		repairs: repairType.repairs.map((repair) => {
			return {
				...repair,
				createdAt: new Date(repair.createdAt),
				updatedAt: new Date(repair.updatedAt),
				getName: (lang: string) => {
					if (lang === "sr") {
						return repair.nameSR || "%Nema imena%";
					} else {
						return repair.nameEN || "%Missing name%";
					}
				},
			};
		}),
		getName: (lang: string) => {
			if (lang === "sr") {
				return repairType.nameSR;
			} else {
				return repairType.nameEN;
			}
		},
	};
}

function formatDate(date: Date) {
	return (
		date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear()
	);
}
