import { useQuery } from "react-query";
import { get } from "../http";
import { Order } from "../types";
import { parseOrder } from "../parser";

async function getActiveRepairOrder() {
	const orders = await get<Order[]>("orders/active");
	return orders.length ? parseOrder(orders[0]) : null;
}

export default function useActiveRepairOrder() {
	const { isLoading, error, data, refetch } = useQuery<Order | null>(
		"activeRepairOrder",
		getActiveRepairOrder
	);

	return {
		isLoading,
		error,
		data,
		refetch,
	};
}
