import { useQuery } from "react-query";
import { get } from "../http";
import { AdditionalCostSchedule, DaySchedule } from "../types";

async function getAdditionalCostSchedules() {
	return (await get<any[]>("/additional-cost-schedule")).map(
		(source) => {
			return {
				...source,
				monday: parseDaySchedule(source.monday as string),
				tuesday: parseDaySchedule(source.tuesday),
				wednesday: parseDaySchedule(source.wednesday),
				thursday: parseDaySchedule(source.thursday),
				friday: parseDaySchedule(source.friday),
				saturday: parseDaySchedule(source.saturday),
				sunday: parseDaySchedule(source.sunday),
			};
		}
	);
}

export default function useAdditionalCostSchedules() {
	const { isLoading, error, data, refetch } = useQuery<AdditionalCostSchedule[]>(
		"additionalCostSchedules",
		getAdditionalCostSchedules
	);

	return {
		isLoading,
		error,
		data,
		refetch,
	};
}

function parseDaySchedule(daySchedule: string): DaySchedule {
	if (!daySchedule || daySchedule.endsWith("disabled")) {
		return {
			startTime: "00:00",
			endTime: "00:00",
			enabled: false,
		};
	}
	const [startTime, endTime] = daySchedule.split("-");
	return {
		startTime,
		endTime,
		enabled: true,
	};
}
