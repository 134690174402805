import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";

function Dropdown({
	placeholder,
	selected,
	options,
	onSelectedChange,
	disabled,
	onClick,
}: {
	placeholder?: string;
	selected: DropdownOption | null;
	options: DropdownOption[];
	onSelectedChange: (selected: DropdownOption) => void;
	disabled?: boolean;
	onClick?: () => void;
}) {
	const dropdownRef = useRef<HTMLDivElement>(null);
	const [isOpen, setOpen] = useState(false);

	const handleClickOutside = (event: MouseEvent) => {
		if (
			dropdownRef.current &&
			!dropdownRef.current.contains(event.target as Node)
		) {
			setOpen(false);
		}
	};

	function onSelectItem(item: DropdownOption) {
		setOpen(false);
		onSelectedChange(item);
	}

	function toggleOpen() {
		if (onClick) onClick();
		if (disabled) return;
		if (options.length === 0) return;
		setOpen((prev) => !prev);
	}

	useEffect(() => {
		document.addEventListener("click", handleClickOutside);
		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, []);

	return (
		<div className={`relative text-black w-full sm:w-[311px] ${disabled ? 'opacity-50 pointer-events-none' : ''}`} ref={dropdownRef}>
			<div
				className="cursor-pointer border border-grey p-4 rounded-lg focus:border-blue hover:border-blue"
				onClick={toggleOpen}
			>
				{selected && <p className="text-black">{selected.text}</p>}
				{!selected && placeholder && <p className="text-grey">{placeholder}</p>}
				<FontAwesomeIcon
					icon={faAngleDown}
					className="text-black absolute top-1/2 -translate-y-1/2 right-4"
				/>
			</div>
			{isOpen && (
				<div className="border rounded-lg absolute top-full translate-y-2 w-full bg-white overflow-hidden z-10">
					{options.map((option, index) => (
						<div
							className="font-medium text-black p-3 hover:bg-grey/10 cursor-pointer"
							key={index}
							onClick={onSelectItem.bind(null, option)}
						>
							{option.text}
						</div>
					))}
				</div>
			)}
		</div>
	);
}

export interface DropdownOption {
	text: string;
}

export default Dropdown;
