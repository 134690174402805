import ChangeEmail from "./ChangeEmail";
import ChangeName from "./ChangeName";
import TitleBox from "./TitleBox";
import UserBox from "./UserBox";
import ChangeLanguage from "./ChangeLanguage";
import ChangePassword from "./ChangePassword";
import { useContext } from "react";
import { UserContext } from "../../stores/userContext";
import { User } from "firebase/auth";

function Settings() {
	const user = useContext(UserContext);
	const isEmailPasswordUser = containstPasswordProvider(user);
	return (
		<div>
			<TitleBox />
			<div className="max-w-4xl sm:mt-8 mb-24 mx-auto">
				<UserBox />
				<ChangeName />
				{isEmailPasswordUser && <ChangeEmail />}
				{isEmailPasswordUser && <ChangePassword />}
				<ChangeLanguage />
			</div>
		</div>
	);
}

function containstPasswordProvider(user: User): boolean {
	return user.providerData.some(
		(provider) => provider.providerId === "password"
	);
}

export default Settings;
