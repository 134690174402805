import { useContext, useState } from "react";
import Button from "../ui/button/Button";
import { UserContext } from "../../stores/userContext";
import Row from "../dashboard/Row";
import { useTranslation } from "react-i18next";
import useBreakpoints from "../../common/useBreakpoints";
import { AnimatePresence } from "framer-motion";
import ChangePopup from "./changepopup/ChangePopup";
import { signInWithEmailAndPassword, updateEmail } from "firebase/auth";
import toast from "react-hot-toast";
import { auth } from "../../common/firebase";
import { get } from "../../common/http";

function ChangeEmail() {
	const user = useContext(UserContext);
	const { t } = useTranslation();
	const { isMobile } = useBreakpoints();
	const [showChangeEmail, setShowChangeEmail] = useState(false);
	const [loading, setLoading] = useState(false);

	function onChangeEmail(values: string[]) {
		if (user.email === null) return;
		setLoading(true);
		signInWithEmailAndPassword(auth, user.email, values[0])
			.then(() => {
				updateEmail(user, values[1])
					.then(() => {
						toast.success(t("settings.success-change-email"));
						setShowChangeEmail(false);
						get("users/update").then(() => {});
					})
					.catch((error) => {
						toast.error(error.message);
					})
					.finally(() => {
						setLoading(false);
					});
			})
			.catch((error) => {
				setLoading(false);
				toast.error(error.message);
			});
	}

	const inputs = [
		{
			defaultValue: "",
			placeholder: t("settings.current-password-placeholder"),
			type: "password",
		},
		{
			defaultValue: "",
			placeholder: t("settings.new-email-placeholder"),
		},
	];

	return (
		<>
			<Row className="sm:flex justify-between items-center">
				<div>
					<h1 className="text-black text-lg font-bold">
						{t("settings.email")}
					</h1>
					<p>{user.email}</p>
				</div>
				<div className="mt-3 sm:mt-0">
					<Button
						name={t("settings.change")}
						width={isMobile ? "full" : "big"}
						onClick={setShowChangeEmail.bind(null, true)}
					/>
				</div>
			</Row>
			<AnimatePresence>
				{showChangeEmail && (
					<ChangePopup
						title={t("settings.update-email")}
						inputs={inputs}
						onSave={onChangeEmail}
						onClose={setShowChangeEmail.bind(null, false)}
						loading={loading}
					/>
				)}
			</AnimatePresence>
		</>
	);
}

export default ChangeEmail;
